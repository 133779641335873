import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {useVirtualizer} from '@tanstack/react-virtual';
import {
  type FC,
  type PropsWithChildren,
  type ReactNode,
  useMemo,
  useRef,
} from 'react';
import {
  MdCheckBoxOutlineBlank,
  MdFlag,
  MdOutlineCheckBox,
  MdSettings,
} from 'react-icons/md';
import {twMerge} from 'tailwind-merge';
import {RichTextDisplay} from '../../components/forms/base-fields/rich-text-field';
import {ItemFilterEditor} from '../../lib/context/item-filter/item-filter-editor.tsx';
import {useItemFilterContext} from '../../lib/context/item-filter/item-filter.tsx';
import {
  type Goal,
  type Todo,
  useGoalsAndTodosQuery,
} from './all-items.generated.ts';
import {useOpenGoalUpsertModal} from './goal-upsert-modal/goal-upsert-modal.tsx';
import {useOpenTodoUpsertModal} from './todo-upsert-modal/todo-upsert-modal.tsx';

const Block: FC<
  PropsWithChildren<{icon: ReactNode; actions: ReactNode; className?: string}>
> = ({children, icon, actions, className}) => {
  return (
    <div
      className={twMerge(
        'flex gap-2 rounded border border-gray-200 p-4 align-baseline',
        className,
      )}
    >
      <div className='mt-1'>{icon}</div>
      <div className='flex-1'>{children}</div>
      <div className='mt-1'>{actions}</div>
    </div>
  );
};

const TodoDisplay: FC<{todo: Todo; className?: string}> = ({
  todo,
  className,
}) => {
  const openTodoUpsertModal = useOpenTodoUpsertModal();

  return (
    <Block
      className={className}
      icon={todo.done ? <MdOutlineCheckBox /> : <MdCheckBoxOutlineBlank />}
      actions={
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <button className='IconButton' aria-label='Customise options'>
              <MdSettings />
            </button>
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className='w-48 rounded-lg bg-white px-1.5 py-1 shadow-md md:w-56'
              sideOffset={5}
            >
              <DropdownMenu.Item
                className='select-none items-center rounded-md p-2 text-xs text-gray-400 outline-none focus:bg-gray-50'
                onClick={() => openTodoUpsertModal({id: todo.id})}
              >
                Bearbeiten
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className='select-none items-center rounded-md p-2 text-xs text-gray-400 outline-none focus:bg-gray-50'
                onClick={() => openTodoUpsertModal({parentTodoId: todo.id})}
              >
                Neues Todo einfügen
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      }
    >
      <RichTextDisplay value={todo.content} />
    </Block>
  );
};

const GoalDisplay: FC<{goal: Goal; className?: string}> = ({
  goal,
  className,
}) => {
  const openTodoUpsertModal = useOpenTodoUpsertModal();
  const openGoalUpsertModal = useOpenGoalUpsertModal();

  return (
    <Block
      className={className}
      icon={<MdFlag />}
      actions={
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <button className='IconButton' aria-label='Customise options'>
              <MdSettings />
            </button>
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className='w-48 rounded-lg bg-white px-1.5 py-1 shadow-md md:w-56'
              sideOffset={5}
            >
              <DropdownMenu.Item
                className='select-none items-center rounded-md p-2 text-xs text-gray-400 outline-none focus:bg-gray-50'
                onClick={() => openGoalUpsertModal({id: goal.id})}
              >
                Bearbeiten
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className='select-none items-center rounded-md p-2 text-xs text-gray-400 outline-none focus:bg-gray-50'
                onClick={() => openGoalUpsertModal({parentId: goal.id})}
              >
                Neues Ziel einfügen
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className='select-none items-center rounded-md p-2 text-xs text-gray-400 outline-none focus:bg-gray-50'
                onClick={() => openTodoUpsertModal({parentGoalId: goal.id})}
              >
                Neues Todo einfügen
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      }
    >
      {goal.content}
    </Block>
  );
};

interface FlatItem {
  element: Goal | Todo;
  indent: number;
}

const colors = [
  'bg-red-500',
  'bg-yellow-500',
  'bg-green-500',
  'bg-blue-500',
  'bg-indigo-500',
  'bg-purple-500',
];

export const AllItems = () => {
  const itemFilter = useItemFilterContext();
  const [{data}] = useGoalsAndTodosQuery({
    variables: {
      hideCompleted: itemFilter.hideCompleted,
      relatedToGoal: itemFilter.goalId,
    },
  });

  const flatItems = useMemo(() => {
    if (!data) {
      return [];
    }

    const flatItems: FlatItem[] = [];
    const allGoals = data.my.allGoals;
    const allTodos = data.my.allTodos;

    function add(element: Goal | Todo | null, indent: number) {
      if (!element) {
        for (const goal of allGoals.filter((goal) => !goal.parentId)) {
          flatItems.push({element: goal, indent});
          add(goal, indent + 1);
        }

        for (const todo of allTodos.filter(
          (todo) => !todo.parentTodoId && !todo.parentGoalId,
        )) {
          flatItems.push({element: todo, indent});
          add(todo, indent + 1);
        }
      } else if (element.__typename === 'Goal') {
        for (const goal of allGoals.filter(
          (goal) => goal.parentId === element.id,
        )) {
          flatItems.push({element: goal, indent});
          add(goal, indent + 1);
        }

        for (const todo of allTodos.filter(
          (todo) => todo.parentGoalId === element.id,
        )) {
          flatItems.push({element: todo, indent});
          add(todo, indent + 1);
        }
      } else if (element.__typename === 'Todo') {
        for (const todo of allTodos.filter(
          (todo) => todo.parentTodoId === element.id,
        )) {
          flatItems.push({element: todo, indent});
          add(todo, indent + 1);
        }
      }
    }

    add(null, 0);

    return flatItems;
  }, [data]);

  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: flatItems.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 45,
  });

  if (!data) {
    return null;
  }

  return (
    <div
      className='-mx-4 -my-6 min-h-0 overflow-y-auto lg:-mx-8 2xl:-mx-10'
      ref={parentRef}
      style={{
        height: 'calc(100vh - 64px)',
        width: `calc(100% + 4rem)`,
      }}
    >
      <div className='mx-auto my-8 w-full max-w-2xl'>
        <ItemFilterEditor className='mb-8' />
        <div
          className='relative'
          style={{
            height: virtualizer.getTotalSize(),
          }}
        >
          {virtualizer.getVirtualItems().map((virtualRow) => {
            const item = flatItems[virtualRow.index];
            return (
              <div
                className='absolute left-0 top-0 flex w-full'
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={virtualizer.measureElement}
                style={{
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                {Array.from({length: item.indent}).map((_, index) => (
                  <div
                    key={index}
                    className={twMerge(
                      'mr-4 w-px',
                      colors[index % colors.length],
                    )}
                  />
                ))}
                {item.element.__typename === 'Goal' && (
                  <GoalDisplay className='flex-1' goal={item.element} />
                )}
                {item.element.__typename === 'Todo' && (
                  <TodoDisplay className='flex-1' todo={item.element} />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
