import * as Types from '../../__generated__/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GoalsAndTodosQueryVariables = Types.Exact<{
  hideCompleted?: Types.Maybe<boolean>;
  relatedToGoal?: Types.Maybe<string>;
}>;

export type GoalsAndTodosQuery = {
  __typename?: 'Query';
  my: {__typename?: 'User'; allGoals: Array<Goal>; allTodos: Array<Todo>};
};

export type Goal = {__typename: 'Goal'; id: string; content: string; parentId?: string | null};

export type Todo = {
  __typename: 'Todo';
  id: string;
  content: string;
  parentGoalId?: string | null;
  parentTodoId?: string | null;
  done: boolean;
};

export const GoalsAndTodosDocument = gql`
  query GoalsAndTodos($hideCompleted: Boolean, $relatedToGoal: GoalId) {
    my {
      allGoals(relatedTo: $relatedToGoal) @export(exportName: "Goal") {
        __typename
        id
        content
        parentId
      }
      allTodos(hideCompleted: $hideCompleted, relatedTo: $relatedToGoal) @export(exportName: "Todo") {
        __typename
        id
        content
        parentGoalId
        parentTodoId
        done
      }
    }
  }
`;

export function useGoalsAndTodosQuery(options?: Omit<Urql.UseQueryArgs<GoalsAndTodosQueryVariables>, 'query'>) {
  return Urql.useQuery<GoalsAndTodosQuery, GoalsAndTodosQueryVariables>({query: GoalsAndTodosDocument, ...options});
}
