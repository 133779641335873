import * as Types from '../../../__generated__/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TodoCreateInput = {
  content: string;
  done: boolean;
  parentGoalId?: string | null;
  parentTodoId?: string | null;
  userId: string;
};

export type TodoUpdateInput = {
  content: string;
  done: boolean;
  id: string;
  parentGoalId?: string | null;
  parentTodoId?: string | null;
};

export type GetTodoQueryVariables = Types.Exact<{
  id: string;
}>;

export type GetTodoQuery = {
  __typename?: 'Query';
  my: {
    __typename?: 'User';
    todo: {
      __typename?: 'Todo';
      id: string;
      content: string;
      parentGoalId?: string | null;
      parentTodoId?: string | null;
      done: boolean;
    };
  };
};

export type CreateTodoMutationVariables = Types.Exact<{
  input: TodoCreateInput;
}>;

export type CreateTodoMutation = {
  __typename?: 'Mutation';
  todoCreate: {__typename?: 'TodoCreatePayload'; success?: boolean | null};
};

export type UpdateTodoMutationVariables = Types.Exact<{
  input: TodoUpdateInput;
}>;

export type UpdateTodoMutation = {
  __typename?: 'Mutation';
  todoUpdate: {__typename?: 'TodoUpdatePayload'; success?: boolean | null};
};

export const GetTodoDocument = gql`
  query GetTodo($id: TodoId!) {
    my {
      todo(id: $id) {
        id
        content
        parentGoalId
        parentTodoId
        done
      }
    }
  }
`;

export function useGetTodoQuery(options: Omit<Urql.UseQueryArgs<GetTodoQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTodoQuery, GetTodoQueryVariables>({query: GetTodoDocument, ...options});
}
export const CreateTodoDocument = gql`
  mutation CreateTodo($input: TodoCreateInput!) {
    todoCreate(create: $input) {
      success
    }
  }
`;

export function useCreateTodoMutation() {
  return Urql.useMutation<CreateTodoMutation, CreateTodoMutationVariables>(CreateTodoDocument);
}
export const UpdateTodoDocument = gql`
  mutation UpdateTodo($input: TodoUpdateInput!) {
    todoUpdate(update: $input) {
      success
    }
  }
`;

export function useUpdateTodoMutation() {
  return Urql.useMutation<UpdateTodoMutation, UpdateTodoMutationVariables>(UpdateTodoDocument);
}
