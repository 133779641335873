import {useLocation} from 'react-router';
import {Navigate} from 'react-router-dom';
import {useAuth} from 'lib/context/auth/auth';

export const RequireAuth = ({children}: {children: JSX.Element}) => {
  const {isLoggedIn} = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    const path =
      location.pathname + (location.search ? '?' + location.search : '');
    return <Navigate to='/auth/login' state={{from: path}} replace />;
  }

  return children;
};
