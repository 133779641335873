import {type FormBuilder, type RegisterOptions} from '@atmina/formbuilder';
import {CheckIcon} from '@heroicons/react/20/solid';
import {
  type ComponentPropsWithRef,
  type FC,
  forwardRef,
  type ReactNode,
} from 'react';
import {type UseFormRegisterReturn} from 'react-hook-form';
import {twMerge} from 'tailwind-merge';

export type CheckboxFieldProps = Omit<
  ComponentPropsWithRef<'input'>,
  'type'
> & {label: ReactNode};
export type CheckboxFormFieldProps = {
  on: FormBuilder<boolean>;
  rules?: RegisterOptions<boolean>;
} & Omit<CheckboxFieldProps, keyof UseFormRegisterReturn>;

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
  ({className, ...props}, ref) => {
    return (
      <label
        className={twMerge(
          'group/checkbox relative flex cursor-pointer flex-row items-center gap-4',
          className,
        )}
      >
        <input
          type='checkbox'
          className='peer opacity-0'
          {...props}
          ref={ref}
          readOnly={typeof props.checked === 'boolean' && !props.onChange}
        />

        <div className='absolute h-5 w-5 rounded-sm border-2 border-gray-500 outline-2 transition-colors duration-75 group-focus-within/checkbox:border-indigo-500 peer-checked:border-indigo-500 peer-checked:bg-indigo-500 peer-disabled:opacity-60' />
        <CheckIcon className='absolute ml-0.5 h-4 w-4 text-white opacity-0 transition-opacity peer-checked:opacity-100' />

        <span className='peer-disabled:opacity-60'>{props.label}</span>
      </label>
    );
  },
);
CheckboxField.displayName = 'CheckboxField';

export const CheckboxFormField: FC<CheckboxFormFieldProps> = ({
  on,
  rules,
  ...props
}) => {
  return <CheckboxField {...on(rules)} {...props} />;
};
