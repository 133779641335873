import {lazy, Suspense} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {Loader} from 'components/loader';
import {RequireAuth} from 'components/route-guards/require-auth';
import {AuthProvider} from 'lib/context/auth/auth';
import {GlobalModalOutlet} from 'lib/context/modal/modal';
import {UrqlProvider} from 'lib/context/urql';
import {AllItemsRoutes} from './routes/all-items';
import RecommendationsRoutes from './routes/recommendation';
import {ShareTarget} from './routes/share-target/share-target.tsx';

const LoggedInLayout = lazy(
  () => import('./components/layouts/logged-in-layout/logged-in-layout'),
);

const AuthRoutes = lazy(() => import('./routes/auth'));

// To make use of the v6 instrumentation, we need to create an element inside BrowserRouter
const AppWithSentry = () => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <GlobalModalOutlet />
        <Suspense
          fallback={
            <Loader
              size='large'
              className='flex h-full w-full items-center justify-center'
            />
          }
        >
          <Routes>
            <Route path='/auth/*' element={<AuthRoutes />} />
            <Route
              path='/*'
              element={
                <RequireAuth>
                  <LoggedInLayout />
                </RequireAuth>
              }
            >
              <Route path='all/*' element={<AllItemsRoutes />} />
              <Route path='share-target/*' element={<ShareTarget />} />
              <Route
                path='recommendations/*'
                element={<RecommendationsRoutes />}
              />
              <Route path='*' element={<Navigate to='/all' />} />
            </Route>
            <Route path='*' element={<Navigate to='/all' />} />
          </Routes>
        </Suspense>
      </AuthProvider>
    </HelmetProvider>
  );
};

export const App = () => (
  <BrowserRouter>
    <HelmetProvider>
      <UrqlProvider>
        <AppWithSentry />
      </UrqlProvider>
    </HelmetProvider>
  </BrowserRouter>
);
