import {type FormBuilder, type RegisterOptions} from '@atmina/formbuilder';
import {type FC, forwardRef} from 'react';
import {type UseFormRegisterReturn} from 'react-hook-form';
import {
  getFieldError,
  setValueAsNumber,
} from 'components/forms/base-fields/common';
import {
  InputField,
  type InputFieldProps,
} from 'components/forms/base-fields/input-field';

export type FloatInputFieldProps = Omit<InputFieldProps, 'type'> & {
  label?: string;
};
export type FloatInputFormFieldProps = {
  on: FormBuilder<number>;
  rules?: RegisterOptions<number>;
} & Omit<FloatInputFieldProps, keyof UseFormRegisterReturn>;

export const FloatInputField = forwardRef<
  HTMLInputElement,
  FloatInputFieldProps
>((props, ref) => {
  return <InputField type='number' step='any' {...props} ref={ref} />;
});
FloatInputField.displayName = 'FloatInputField';

export const FloatInputFormField: FC<FloatInputFormFieldProps> = ({
  on,
  rules,
  ...props
}) => {
  const rulesWithNumber = {...rules, setValueAs: setValueAsNumber};

  return (
    <FloatInputField
      {...on(rulesWithNumber as never)}
      {...props}
      error={getFieldError(on)}
    />
  );
};

export type NumericInputFieldProps = Omit<FloatInputFieldProps, 'step'>;
export type NumericInputFormFieldProps = Omit<FloatInputFormFieldProps, 'step'>;

export const NumericInputField = forwardRef<
  HTMLInputElement,
  NumericInputFieldProps
>((props, ref) => {
  return <FloatInputField {...props} step={1} ref={ref} />;
});
NumericInputField.displayName = 'NumericInputField';

export const NumericInputFormField: FC<NumericInputFormFieldProps> = (
  props,
) => <FloatInputFormField {...props} step={1} />;
