import {type FormBuilder} from '@atmina/formbuilder';
import {CodeHighlightNode, CodeNode} from '@lexical/code';
import {AutoLinkNode, LinkNode} from '@lexical/link';
import {ListItemNode, ListNode} from '@lexical/list';
import {$convertFromMarkdownString, TRANSFORMERS} from '@lexical/markdown';
import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin';
import {AutoLinkPlugin} from '@lexical/react/LexicalAutoLinkPlugin';
import {
  type InitialEditorStateType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {HorizontalRuleNode} from '@lexical/react/LexicalHorizontalRuleNode';
import {LinkPlugin} from '@lexical/react/LexicalLinkPlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import {
  DEFAULT_TRANSFORMERS,
  MarkdownShortcutPlugin,
} from '@lexical/react/LexicalMarkdownShortcutPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {HeadingNode, QuoteNode} from '@lexical/rich-text';
import {TableNode, TableCellNode, TableRowNode} from '@lexical/table';
import {type EditorState} from 'lexical';
import {type FC, useCallback, useEffect} from 'react';
import ToolbarPlugin from './plugins/toolbar-plugin.tsx';
import {exampleTheme} from './theme/editor-theme.ts';

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    const fullMatch = match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
      attributes: {rel: 'noopener', target: '_blank'},
    };
  },
];

const nodes = [
  HeadingNode,
  ListNode,
  ListItemNode,
  QuoteNode,
  CodeNode,
  CodeHighlightNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  AutoLinkNode,
  LinkNode,
  HorizontalRuleNode,
];

function wrapInEditorStateJson(value: string): InitialEditorStateType {
  if (!value.startsWith('{') || !value.endsWith('}')) {
    return () => $convertFromMarkdownString(value, TRANSFORMERS);
  }

  return value;
}

export const LinksInNewTabPlugin: FC = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor) return;
    const removeLinkNodeListener = editor.registerNodeTransform(
      LinkNode,
      (node) => {
        if (!node) return;

        const dom = editor.getElementByKey(node.__key);
        if (!dom) return;
        dom.setAttribute('target', '_blank');
      },
    );

    const removeAutoLinkNodeListener = editor.registerNodeTransform(
      AutoLinkNode,
      (node) => {
        if (!node) return;
        const dom = editor.getElementByKey(node.__key);
        if (!dom) return;
        dom.setAttribute('target', '_blank');
      },
    );

    return () => {
      removeLinkNodeListener();
      removeAutoLinkNodeListener();
    };
  }, [editor]);

  return null;
};

export const RichTextField: FC<{
  value?: string;
  onChange?: (v: string) => void;
  onBlur?: () => void;
}> = ({value, onChange}) => {
  const onEditorChange = useCallback(
    (editorState: EditorState) => {
      if (!onChange) return;

      editorState.read(() => {
        onChange(JSON.stringify(editorState.toJSON()));
      });
    },
    [onChange],
  );

  return (
    <LexicalComposer
      initialConfig={{
        namespace: 'editor',
        onError(error) {
          throw error;
        },
        nodes,
        theme: exampleTheme,
        editorState: wrapInEditorStateJson(value ?? ''),
      }}
    >
      <div className='editor-container'>
        <ToolbarPlugin />
        <div className='editor-inner'>
          <RichTextPlugin
            contentEditable={<ContentEditable className='editor-input' />}
            placeholder={
              <div className='editor-placeholder'>Enter some rich text...</div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin matchers={MATCHERS} />
          <MarkdownShortcutPlugin transformers={DEFAULT_TRANSFORMERS} />
          <OnChangePlugin
            onChange={onEditorChange}
            ignoreSelectionChange={true}
          />
        </div>
      </div>
    </LexicalComposer>
  );
};

export const RichTextFormField: FC<{on: FormBuilder<string>}> = ({on}) => {
  const controller = on.$useController();

  const value = controller.field.value;

  if (value === undefined) return null;
  return (
    <RichTextField
      value={value}
      onChange={controller.field.onChange}
      onBlur={controller.field.onBlur}
    />
  );
};

export const RichTextDisplay: FC<{value: string}> = ({value}) => {
  return (
    <LexicalComposer
      key={value}
      initialConfig={{
        editorState: wrapInEditorStateJson(value ?? ''),
        namespace: 'readonly',
        onError(error) {
          throw error;
        },
        nodes,
        theme: {
          link: 'text-blue-500 underline',
        },
        editable: false,
      }}
    >
      <RichTextPlugin
        contentEditable={<ContentEditable />}
        placeholder={null}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <HistoryPlugin />
      <AutoFocusPlugin />
      <ListPlugin />
      <LinkPlugin />
      <AutoLinkPlugin matchers={MATCHERS} />
      <MarkdownShortcutPlugin transformers={DEFAULT_TRANSFORMERS} />
      <LinksInNewTabPlugin />
    </LexicalComposer>
  );
};
