import {Route, Routes} from 'react-router-dom';
import {Title} from 'components/title';
import {Recommendations} from './recommendations.tsx';

const RecommendationsRoutes = () => (
  <Title title='Recommendations'>
    <Routes>
      <Route index element={<Recommendations />} />
    </Routes>
  </Title>
);

export default RecommendationsRoutes;
