import {useEffect, useRef, useState, useSyncExternalStore} from 'react';

export class LoadingSignal {
  public subscriptions = new Set<(loading: boolean) => void>();

  private isLoading = false;

  public set loading(loading: boolean) {
    this.setLoading(loading);
  }

  public get loading() {
    return this.isLoading;
  }

  public constructor(initialLoading = false) {
    this.isLoading = initialLoading;
    this.subscribe = this.subscribe.bind(this);
  }

  public subscribe(callback: (loading: boolean) => void) {
    this.subscriptions.add(callback);
    return () => this.unsubscribe(callback);
  }

  public unsubscribe(callback: (loading: boolean) => void) {
    this.subscriptions.delete(callback);
  }

  public async wrapLoading<Result>(promise: Promise<Result>) {
    this.setLoading(true);
    try {
      const result = await promise;
      this.setLoading(false);
      return result;
    } catch (error) {
      this.setLoading(false);
      throw error;
    }
  }

  private notifySubscribers() {
    this.subscriptions.forEach((callback) => callback(this.isLoading));
  }

  public setLoading(loading: boolean) {
    if (this.isLoading === loading) {
      return;
    }

    this.isLoading = loading;
    this.notifySubscribers();
  }
}

export const useLoadingSignal = (
  existingSignal?: LoadingSignal,
  initialLoading?: boolean,
) => {
  const loadingSignalRef = useRef<LoadingSignal>();

  if (existingSignal) {
    return existingSignal;
  }

  let loadingSignal = loadingSignalRef.current;
  if (!loadingSignal) {
    loadingSignal = new LoadingSignal(initialLoading);
    loadingSignalRef.current = loadingSignal;
  }

  return loadingSignal;
};

export const useLoadingSignalState = (loadingSignal: LoadingSignal) => {
  const [loading, setLoading] = useState(loadingSignal.loading);
  useEffect(() => {
    return loadingSignal.subscribe(setLoading);
  }, [loadingSignal]);
  return loading;
};

export class CombinedLoadingSignal extends LoadingSignal {
  private signals = new Set<LoadingSignal>();

  private deferTimeouts = new WeakMap<
    LoadingSignal,
    ReturnType<typeof setTimeout>
  >();

  public constructor(signals: LoadingSignal[]) {
    super();

    signals.forEach((signal) => this.add(signal));
    this.onSignalChange = this.onSignalChange.bind(this);
  }

  private onSignalChange() {
    this.setLoading(Array.from(this.signals).some((signal) => signal.loading));
  }

  private deferAction(signal: LoadingSignal, action: () => void) {
    const timeout = this.deferTimeouts.get(signal);
    if (timeout) {
      clearTimeout(timeout);
      this.deferTimeouts.delete(signal);
    }

    this.deferTimeouts.set(
      signal,
      setTimeout(() => {
        this.deferTimeouts.delete(signal);
        action();
      }, 0),
    );
  }

  public add(signal: LoadingSignal) {
    this.signals.add(signal);
    signal.subscribe(this.onSignalChange);
    this.deferAction(signal, this.onSignalChange);
  }

  public remove(signal: LoadingSignal) {
    this.signals.delete(signal);
    signal.unsubscribe(this.onSignalChange);
    this.deferAction(signal, this.onSignalChange);
  }
}

export const globalLoadingSignal = new CombinedLoadingSignal([]);

export const useGlobalLoadingSignalState = () => {
  return useSyncExternalStore<boolean>(
    globalLoadingSignal.subscribe,
    () => globalLoadingSignal.loading,
  );
};

export const useGlobalLoadingSignal = () => {
  const loadingSignal = useLoadingSignal();

  useEffect(() => {
    globalLoadingSignal.add(loadingSignal);
    return () => globalLoadingSignal.remove(loadingSignal);
  }, [loadingSignal]);

  return loadingSignal;
};

export const useGlobalLoadingStateTimed = () => {
  const loading = useGlobalLoadingSignalState();
  const [loadingTimed, setLoadingTimed] = useState(false);

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => setLoadingTimed(false), 300);
      return () => clearTimeout(timer);
    }

    const timer = setTimeout(() => setLoadingTimed(true), 100);
    return () => clearTimeout(timer);
  }, [loading]);

  return loadingTimed;
};
