import {
  type ComponentPropsWithoutRef,
  type FC,
  type ReactNode,
  useId,
} from 'react';
import {twMerge} from 'tailwind-merge';

export type FloatingLabelOutlineProps = Omit<
  ComponentPropsWithoutRef<'div'>,
  'children'
> & {
  label?: string;
  error?: string | true;
  shrinkLabel?: boolean;

  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  children: (id: string) => ReactNode;
};

export const FloatingLabelOutline: FC<FloatingLabelOutlineProps> = ({
  label,
  error,
  shrinkLabel,
  className,
  startAdornment,
  endAdornment,
  children,

  ...rest
}) => {
  const id = useId();

  return (
    <div
      className={twMerge(
        'group/float-label relative isolate mb-2 flex cursor-text',
        label && 'mt-2',
        error && 'mb-6',
        className,
      )}
      {...rest}
    >
      {startAdornment && (
        <div className='mt-[1.5px] flex min-w-[40px] shrink-0 items-center justify-center overflow-hidden rounded-l'>
          {typeof startAdornment === 'string' ? (
            <div className='pl-3 pr-2'>{startAdornment}</div>
          ) : (
            startAdornment
          )}
        </div>
      )}
      {children(id)}
      {endAdornment && (
        <div className='ml-auto mt-[1.5px] flex min-w-[40px] shrink-0 items-center justify-center overflow-hidden rounded-r'>
          {typeof endAdornment === 'string' ? (
            <div className='pl-2 pr-3'>{endAdornment}</div>
          ) : (
            endAdornment
          )}
        </div>
      )}

      {!startAdornment && label && (
        <label
          className={twMerge(
            'pointer-events-none absolute left-0 top-0 z-10 max-w-[calc(133%-32px)] origin-top-left -translate-y-2 translate-x-3.5 scale-75 select-none truncate text-gray-700 transition-all',
            shrinkLabel === false &&
              'max-w-[calc(100%-26px)] translate-y-2 scale-100',
            shrinkLabel === undefined &&
              'group-focus-within/float-label:!max-w-[calc(133%-32px)] group-focus-within/float-label:!-translate-y-2 group-focus-within/float-label:!scale-75 group-focus-within/float-label:text-indigo-500 peer-placeholder-shown:max-w-[calc(100%-26px)] peer-placeholder-shown:translate-y-2 peer-placeholder-shown:scale-100',
            error && '!text-red-600',
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}

      <fieldset
        className={twMerge(
          'absolute inset-0 -top-2 -z-10 box-content min-w-0 rounded border border-gray-400 px-2 text-sm group-focus-within/float-label:border-2 group-focus-within/float-label:border-indigo-500 peer-disabled:bg-gray-50',
          error && '!border-red-600',
          !startAdornment && 'peer-placeholder-shown:[&>legend]:max-w-0',
        )}
      >
        <legend
          className={twMerge(
            'max-w-full overflow-hidden transition-[max-width] duration-100',
            !startAdornment && 'text-transparent',
            startAdornment && 'group-focus-within/float-label:text-indigo-500',
            shrinkLabel === false && 'max-w-0',
            shrinkLabel === undefined &&
              'group-focus-within/float-label:!max-w-full',
            !label && 'w-0',
          )}
        >
          <span className='relative -left-0.5 -top-0.5 whitespace-nowrap px-2 text-xs'>
            {label}
          </span>
        </legend>
      </fieldset>

      {typeof error === 'string' && (
        <span className='absolute left-0 top-10 text-xs text-red-600'>
          {error}
        </span>
      )}
    </div>
  );
};
