import {AnimatePresence, motion} from 'framer-motion';
import {
  type FC,
  type PropsWithChildren,
  type ReactElement,
  Suspense,
  useEffect,
  useRef,
} from 'react';
import {createPortal} from 'react-dom';
import {twMerge} from 'tailwind-merge';
import {create} from 'zustand';
import {Loader} from 'components/loader';
import {
  closePreventedBySome,
  PreventModalCloseContext,
  type PreventModalCloseListener,
} from 'lib/context/modal/prevent-modal-close';

export const useEscapeKeyPressed = (
  onEscapeKeyUp: (event: KeyboardEvent) => void,
) => {
  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onEscapeKeyUp(e);
      }
    };

    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [onEscapeKeyUp]);
};

type AnimatedModalContainerProps = {
  onBackdropMouseDown: () => void;
  className?: string;
};

export const AnimatedModalContainer: FC<
  PropsWithChildren<AnimatedModalContainerProps>
> = ({children, onBackdropMouseDown, className}) => {
  return (
    <motion.div
      role='dialog'
      className={twMerge('fixed inset-0 z-[1000] bg-black/30', className)}
      onMouseDown={onBackdropMouseDown}
      initial={{opacity: 0}}
      transition={{duration: 0.1}}
      animate={{opacity: 1}}
      exit={{opacity: 0, transition: {duration: 0}}}
    >
      {children}
    </motion.div>
  );
};

type ModalRenderer<ReturnData> = (
  submit: (data: ReturnData) => void,
  close: (ignorePreventClose?: boolean) => void,
) => ReactElement;

type OpenModal = <ReturnData>(
  render: ModalRenderer<ReturnData>,
  options?: ModalOptions,
) => Promise<ReturnData | null>;

type RenderModalStoreState = {
  globalElements: ReactElement[];
  localElementsUpdate: number;
  open: OpenModal;
  addElement: (element: ReactElement, localKey?: number) => void;
  removeElement: (element: ReactElement, localKey?: number) => void;
};

type ModalOptions = {
  localKey?: number;
  backdropClickCloses?: boolean;
  closeOnEsc?: boolean;
};

const globalRefs = {
  uniqueKey: 0,
  openModals: 0,
};

const localElements = new Map<number, ReactElement>();

const useRenderModalStore = create<RenderModalStoreState>((set, get) => ({
  globalElements: [],
  localElementsUpdate: 0,
  open: <ReturnData,>(
    render: ModalRenderer<ReturnData>,
    options?: ModalOptions,
  ) => {
    const {addElement, removeElement} = get();

    let resolver: (data: ReturnData | null) => void;
    const promise = new Promise<ReturnData | null>((resolve) => {
      return (resolver = (data) => {
        globalRefs.openModals--;
        return resolve(data);
      });
    });

    const preventCloseListeners = new Set<PreventModalCloseListener>();

    const addPreventCloseListener = (listener: PreventModalCloseListener) => {
      preventCloseListeners.add(listener);

      return () => preventCloseListeners.delete(listener);
    };

    const resolveIfNotPrevented = async (
      data: ReturnData | null,
      ignorePreventClose?: boolean,
    ) => {
      if (ignorePreventClose) {
        return resolver(data);
      }
      const prevented = await closePreventedBySome(preventCloseListeners);

      if (!prevented) {
        resolver(data);
      }
    };

    const ModalWrapper: FC<PropsWithChildren<{index: number}>> = ({
      children,
      index,
    }) => {
      useEscapeKeyPressed(() => {
        if (
          options?.closeOnEsc !== false &&
          index === globalRefs.openModals - 1
        ) {
          void resolveIfNotPrevented(null);
        }
      });

      return <>{children}</>;
    };

    const uniqueKey = globalRefs.uniqueKey++;
    const index = globalRefs.openModals++;

    let modal = (
      <ModalWrapper key={`wrapper-${uniqueKey}`} index={index}>
        <PreventModalCloseContext.Provider
          value={{addPreventListener: addPreventCloseListener}}
        >
          <AnimatedModalContainer
            onBackdropMouseDown={() => {
              if (options?.backdropClickCloses) {
                resolveIfNotPrevented(null);
              }
            }}
            key={uniqueKey}
          >
            <Suspense
              fallback={
                <div className='grid h-full w-full place-items-center'>
                  <Loader size='large' />
                </div>
              }
            >
              {render(
                (data) => resolveIfNotPrevented(data),
                (ignorePreventClose) =>
                  resolveIfNotPrevented(null, ignorePreventClose),
              )}
            </Suspense>
          </AnimatedModalContainer>
        </PreventModalCloseContext.Provider>
      </ModalWrapper>
    );

    if (options?.localKey !== undefined) {
      modal = createPortal(
        modal,

        document.getElementById('modal-root')!,
      );
    }

    addElement(modal, options?.localKey);

    return promise.then((result) => {
      removeElement(modal, options?.localKey);
      return result;
    });
  },
  addElement: (element, localKey) => {
    if (localKey !== undefined) {
      localElements.set(localKey, element);
      set((state) => ({
        localElementsUpdate: state.localElementsUpdate + 1,
      }));
    } else {
      set((state) => ({
        globalElements: [...state.globalElements, element],
      }));
    }
  },
  removeElement: (element, localKey) => {
    if (localKey !== undefined) {
      localElements.delete(localKey);
      set((state) => ({
        localElementsUpdate: state.localElementsUpdate + 1,
      }));
    } else {
      set((state) => ({
        globalElements: state.globalElements.filter((el) => el !== element),
      }));
    }
  },
}));

export const GlobalModalOutlet = () => {
  const globalElements = useRenderModalStore((store) => store.globalElements);

  return createPortal(
    <AnimatePresence>{globalElements}</AnimatePresence>,

    document.getElementById('modal-root')!,
  );
};

let modalKey = 0;

export const useOpenModal = () => {
  return useRenderModalStore((state) => state.open);
};

export const useOpenLocalModal = () => {
  const open = useOpenModal();
  const localkeyRef = useRef<number>();
  const openRef = useRef<OpenModal>();

  let localKey = localkeyRef.current;

  if (localKey === undefined) {
    localKey = modalKey++;

    localkeyRef.current = localKey;
  }

  let wrappedOpen = openRef.current;

  if (wrappedOpen === undefined) {
    wrappedOpen = (render, options) =>
      open(render, options ? {...options, localKey} : {localKey});
    openRef.current = wrappedOpen;
  }

  const element = localElements.get(localKey) ?? null;

  useRenderModalStore((state) => state.localElementsUpdate);

  return {open: wrappedOpen, modal: element};
};
