// Based on https://github.com/gregberge/react-merge-refs

import {type MutableRefObject, type LegacyRef, type RefCallback} from 'react';

export default function mergeRefs<T = unknown>(
  ...refs: (MutableRefObject<T> | LegacyRef<T> | null | undefined)[]
): RefCallback<T> {
  return (value) => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as MutableRefObject<T | null>).current = value;
      }
    }
  };
}
