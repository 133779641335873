import {
  createClient as createWSClient,
  type SubscribePayload,
} from 'graphql-ws';
import {subscriptionExchange as urqlSubscriptionExchange} from 'urql';

let requestId = 0;
let restartRequestedBeforeConnected = false;
let graceFullyRestart = () => {
  restartRequestedBeforeConnected = true;
};

const subscriptionClient = createWSClient({
  url: `${window.location.protocol.replace('http', 'ws')}//${
    window.location.host
  }/graphql`,
  generateID: () => (requestId++).toString(),
  retryAttempts: 1,
  on: {
    connected: (socket) => {
      if (!(socket instanceof WebSocket))
        throw new Error('Socket not a WebSocket');

      graceFullyRestart = () => {
        if (socket.readyState === WebSocket.OPEN)
          socket.close(4025, 'Client Restart');
      };

      if (restartRequestedBeforeConnected) {
        restartRequestedBeforeConnected = false;
        graceFullyRestart();
      }
    },
  },
});

export const subscriptionExchange = urqlSubscriptionExchange({
  forwardSubscription(operation) {
    return {
      subscribe: (sink) => {
        const dispose = subscriptionClient.subscribe(
          operation as SubscribePayload,
          sink,
        );
        return {
          unsubscribe: dispose,
        };
      },
    };
  },
  enableAllOperations: true,
  isSubscriptionOperation: (operation) => {
    // We want to handle all mutation types with the fetch exchange, so we ignore it as well as teardown operations
    return operation.kind === 'subscription' || operation.kind === 'query';
  },
});

export const reconnectSubscriptionClient = () => graceFullyRestart();
