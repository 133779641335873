import {type FormBuilder} from '@atmina/formbuilder';
import {type FC, useCallback, useMemo} from 'react';
import {type SingleValue} from 'react-select';
import Creatable from 'react-select/creatable';
import {type Todo, useGetTodosQuery} from './todo-select.generated.ts';

export const TodoSelectField: FC<{on: FormBuilder<string | undefined>}> = ({
  on,
}) => {
  const [{data}] = useGetTodosQuery();

  const {onChange} = on.$useController().field;
  const currentTodoId = on.$useWatch();

  const handleChange = useCallback(
    (value: SingleValue<Todo>) => {
      onChange(value?.id);
    },
    [onChange],
  );

  const options = useMemo(
    () => data?.my.allTodos.map((g) => g),
    [data?.my.allTodos],
  );

  if (!options) return null;

  return (
    <label className='block'>
      <span className='block text-sm font-medium leading-5 text-gray-700'>
        Gehört zu Todo
      </span>
      <Creatable
        className='z-60'
        menuPortalTarget={document.querySelector('body')}
        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
        value={options?.find((br) => br.id === currentTodoId)}
        isClearable
        isValidNewOption={() => false}
        options={options}
        getOptionLabel={(r) => r.content}
        getOptionValue={(r) => r.id}
        onChange={handleChange}
      />
    </label>
  );
};
