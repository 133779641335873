import {type FormBuilder, type RegisterOptions} from '@atmina/formbuilder';
import * as Popover from '@radix-ui/react-popover';
import {format, isValid, parse} from 'date-fns';
import localeDe from 'date-fns/locale/de';
import {type FC, forwardRef, useState} from 'react';
import {DayPicker, type DayPickerSingleProps} from 'react-day-picker';
import {type UseFormRegisterReturn} from 'react-hook-form';

import 'react-day-picker/dist/style.css';

import {twMerge} from 'tailwind-merge';
import {getFieldError} from 'components/forms/base-fields/common';
import {InputField} from 'components/forms/base-fields/input-field';

export type DateFieldProps = {
  label?: string;
  error?: string | true;
  disabled?: boolean;
  dayDisabled?: DayPickerSingleProps['disabled'];
  onSelect: (date: Date | undefined) => void;
} & Omit<DayPickerSingleProps, 'mode' | 'disabled' | 'onSelect'>;

export type DateFormFieldProps = {
  on: FormBuilder<Date | null>;
  rules?: RegisterOptions<Date | null>;
} & Omit<DateFieldProps, keyof UseFormRegisterReturn | 'onSelect' | 'selected'>;

export const DateField = forwardRef<HTMLInputElement, DateFieldProps>(
  ({error, label, className, disabled, required, ...props}, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [typedValue, setTypedValue] = useState<string | undefined>(undefined);

    const selectedDate = props.selected;
    const formatted =
      typedValue ?? (selectedDate ? format(selectedDate, 'dd.MM.yyyy') : '');

    return (
      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger className={twMerge('w-full', className)} asChild>
          {/* div prevents Radix from messing with the input */}
          <div>
            <InputField
              value={formatted}
              className='cursor-pointer text-left'
              error={error}
              label={label}
              ref={ref}
              required={required}
              disabled={disabled}
              placeholder='DD.MM.YYYY'
              onInput={(e) => setTypedValue(e.currentTarget.value)}
              onBlur={(e) => {
                const parsedDate = parse(
                  e.target.value,
                  'dd.MM.yyyy',
                  new Date(),
                );
                if (isValid(parsedDate)) props.onSelect?.(parsedDate);
                else setTypedValue(undefined);
              }}
            />
          </div>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content align='start' sideOffset={8}>
            <DayPicker
              className='!m-0 rounded border bg-white p-3'
              weekStartsOn={1}
              {...props}
              disabled={props.dayDisabled}
              mode='single'
              defaultMonth={selectedDate}
              locale={localeDe}
              onSelect={(date) => {
                if (!date) return;

                const withoutTimezone = new Date(
                  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
                );

                setIsOpen(false);
                props.onSelect?.(withoutTimezone);
              }}
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  },
);
DateField.displayName = 'DateField';

export const DateFormField: FC<DateFormFieldProps> = ({
  on,
  rules,
  ...props
}) => {
  const {
    field: {value, onChange},
  } = on.$useController({rules});

  return (
    <DateField
      {...props}
      error={getFieldError(on)}
      disabled={rules?.disabled}
      selected={value ?? undefined}
      onSelect={onChange}
    />
  );
};
