import {useFormBuilder} from '@atmina/formbuilder';
import {type FC, useCallback} from 'react';
import {twMerge} from 'tailwind-merge';
import {CheckboxFormField} from '../../../components/forms/base-fields';
import {GoalSelectField} from '../../../components/forms/domain-fields/goal-select/goal-select.tsx';
import Form from '../../../components/forms/form.tsx';
import {useItemFilterContext} from './item-filter.tsx';

interface ItemFilterForm {
  goalId?: string;
  hideCompleted: boolean;
}

export const ItemFilterEditor: FC<{
  className?: string;
  doNotAskCompletionStatus?: boolean;
}> = ({className, doNotAskCompletionStatus}) => {
  const context = useItemFilterContext();
  const builder = useFormBuilder<ItemFilterForm>({
    defaultValues: {
      goalId: context.goalId,
      hideCompleted: context.hideCompleted,
    },
  });

  builder.watch(
    useCallback(
      (values: Partial<ItemFilterForm>) => {
        context.setGoalId(values.goalId);

        if (values.hideCompleted !== undefined) {
          context.setHideCompleted(values.hideCompleted);
        }
      },
      [context],
    ),
  );

  return (
    <div className={twMerge(className, 'bg-blue-100 px-2 py-1')}>
      <Form builder={builder} className='flex gap-4 space-y-6'>
        <GoalSelectField
          on={builder.fields.goalId}
          className='flex-1'
          label='Zeige aus Ziel'
          registerAsActions={true}
        />
        {doNotAskCompletionStatus || (
          <CheckboxFormField
            label='Verstecke abgeschlossene'
            on={builder.fields.hideCompleted}
            className='flex-1'
          />
        )}
      </Form>
    </div>
  );
};
