import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

export type ItemFilterContextValue = {
  goalId?: string;
  hideCompleted: boolean;
  setGoalId: (goalId?: string) => void;
  setHideCompleted: (hideCompleted: boolean) => void;
};

export const ItemFilterContext = createContext<ItemFilterContextValue>({
  goalId: undefined,
  hideCompleted: false,
  setGoalId: () => {},
  setHideCompleted: () => {},
});

export const useItemFilterContext = () => {
  const context = useContext(ItemFilterContext);

  if (!context) {
    throw new Error(
      `usePreventModalCloseContext can only be used inside a <PreventModalClose /> Component`,
    );
  }

  return context;
};

export const ItemFilterProvider: FC<PropsWithChildren> = ({children}) => {
  const [goalId, setGoalId] = useState<string | undefined>(undefined);
  const [hideCompleted, setHideCompleted] = useState(true);

  const contextValue = useMemo(() => {
    return {
      goalId,
      hideCompleted,
      setGoalId,
      setHideCompleted,
    };
  }, [goalId, hideCompleted, setGoalId, setHideCompleted]);

  return (
    <ItemFilterContext.Provider value={contextValue}>
      {children}
    </ItemFilterContext.Provider>
  );
};
