import {retryExchange} from '@urql/exchange-retry';
import {type FC, type PropsWithChildren, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {
  type Client,
  type CombinedError,
  createClient,
  errorExchange,
  fetchExchange,
  Provider,
} from 'urql';
import customScalarsExchange from 'urql-custom-scalars-exchange';
import introspectionSchema from '__generated__/introspection';
import {notifyGlobalLoadingExchange} from 'lib/context/urql/loading';
import {subscriptionExchange} from 'lib/context/urql/subscription';

export const isUnknownError = (error: CombinedError): boolean => {
  return error.graphQLErrors.some(({extensions: {code}}) => code === 'UNKNOWN');
};

export const isUnauthenticatedError = (error: CombinedError): boolean => {
  const authErrors = ['AUTH_NOT_AUTHORIZED', 'UNAUTHENTICATED'];

  if (
    error.graphQLErrors.some(({extensions: {code}}) =>
      authErrors.includes(code as string),
    )
  ) {
    return true;
  }

  if (
    authErrors.includes(
      (error.networkError as unknown as {extensions: {code: string}})
        ?.extensions?.code,
    )
  ) {
    return true;
  }

  if (
    Array.isArray(error.networkError) &&
    error.networkError.some(({extensions: {code}}) => authErrors.includes(code))
  ) {
    return true;
  }

  return false;
};

export const UrqlProvider: FC<PropsWithChildren> = ({children}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const clientRef = useRef<Client | null>(null);

  const createUrqlClient = () =>
    createClient({
      url: '/graphql',
      requestPolicy: 'network-only',
      fetchOptions: {headers: {'graphql-preflight': '1'}},
      exchanges: [
        customScalarsExchange({
          schema: introspectionSchema,
          scalars: {
            DateTime: (value?: unknown) =>
              typeof value === 'string' ? new Date(value) : value,
            Date: (value?: unknown) =>
              typeof value === 'string' ? new Date(value) : value,
          },
        }),
        errorExchange({
          onError: (error: CombinedError) => {
            if (!isUnauthenticatedError(error)) {
              console.error(error);
            }
          },
        }),
        retryExchange({
          initialDelayMs: 10,
          maxDelayMs: 10000,
          randomDelay: true,
          maxNumberAttempts: 3,
          retryIf: (error: CombinedError, operation) => {
            return (
              !!error.networkError ||
              (operation.kind === 'query' &&
                !isUnauthenticatedError(error) &&
                isUnknownError(error))
            );
          },
        }),
        errorExchange({
          onError: (error: CombinedError) => {
            if (isUnauthenticatedError(error)) {
              document.cookie =
                'DoLoginShadow=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
              navigate('/auth/login', {state: {from: location.pathname}});
            }
          },
        }),
        notifyGlobalLoadingExchange,
        subscriptionExchange,
        fetchExchange,
      ],
    });

  const getClient = () => {
    let client = clientRef.current;
    if (!client) {
      client = createUrqlClient();
    }

    clientRef.current = client;

    return client;
  };

  return <Provider value={getClient()}>{children}</Provider>;
};
