import {type Exchange, type Operation} from 'urql';
import {pipe, tap} from 'wonka';
import {globalLoadingSignal, LoadingSignal} from 'lib/hooks/use-loading-signal';

export const notifyGlobalLoadingExchange: Exchange = ({forward}) => {
  const loadingSignals = new Map<number, LoadingSignal>();
  const onOperationStart = (operation: Operation) => {
    const loadingSignal = new LoadingSignal(true);
    const existingSignal = loadingSignals.get(operation.key);
    if (existingSignal) {
      globalLoadingSignal.remove(existingSignal);
    }
    loadingSignals.set(operation.key, loadingSignal);
    globalLoadingSignal.add(loadingSignal);
  };

  const onOperationEnd = (operation: Operation) => {
    const loadingSignal = loadingSignals.get(operation.key);
    if (loadingSignal) {
      loadingSignals.delete(operation.key);
      globalLoadingSignal.remove(loadingSignal);
    }
  };

  return (ops$) => {
    return pipe(
      ops$,
      tap((operation) => {
        if (operation.kind === 'query') {
          onOperationStart(operation);
        } else if (operation.kind === 'teardown') {
          onOperationEnd(operation);
        }
      }),
      forward,
      tap((result) => onOperationEnd(result.operation)),
    );
  };
};
