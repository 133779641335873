import * as Types from '../../../__generated__/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type LoginInput = {
  email: string;
  password: string;
};

export type LoginMutationVariables = Types.Exact<{
  input: LoginInput;
}>;

export type LoginMutation = {__typename?: 'Mutation'; login: {__typename?: 'LoginPayload'; success?: boolean | null}};

export type LogoutMutationVariables = Types.Exact<{[key: string]: never}>;

export type LogoutMutation = {
  __typename?: 'Mutation';
  logout: {__typename?: 'LogoutPayload'; success?: boolean | null};
};

export type MyQueryVariables = Types.Exact<{[key: string]: never}>;

export type MyQuery = {__typename?: 'Query'; my: UserFragment};

export type UserFragment = {__typename?: 'User'; id: string; name: string; email: string};

export const LoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      success
    }
  }
`;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
}
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      success
    }
  }
`;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
}
export const MyDocument = gql`
  query My {
    my @export(exportName: "UserFragment") {
      id
      name
      email
    }
  }
`;

export function useMyQuery(options?: Omit<Urql.UseQueryArgs<MyQueryVariables>, 'query'>) {
  return Urql.useQuery<MyQuery, MyQueryVariables>({query: MyDocument, ...options});
}
