import {type FC, useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useOpenTodoUpsertModal} from '../all-items/todo-upsert-modal/todo-upsert-modal.tsx';

export const ShareTarget: FC = () => {
  const [searchParams] = useSearchParams();
  const openTodoUpsertModal = useOpenTodoUpsertModal();
  const text = [
    searchParams.get('text'),
    searchParams.get('title'),
    searchParams.get('url'),
    searchParams.get('description'),
  ]
    .filter(Boolean)
    .join('\n');

  useEffect(() => {
    if (!text) return;
    openTodoUpsertModal({
      content: text,
    });
  }, [openTodoUpsertModal, text]);

  return <div>{!text && 'No content shared'}</div>;
};
