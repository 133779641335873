import {ExclamationTriangleIcon} from '@heroicons/react/24/outline';
import {type FC, useCallback} from 'react';
import {Button} from 'components/button';
import {DialogBase, DialogButtons} from 'components/dialog-base/dialog-base';
import {useOpenModal} from 'lib/context/modal/modal';

export interface UseErrorDisplayProps {
  errors: string[];
  onClose: () => void;
}

const ErrorDialog: FC<UseErrorDisplayProps> = ({errors, onClose}) => {
  return (
    <DialogBase>
      <div className='sm:flex sm:items-start'>
        <div className='mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
          <ExclamationTriangleIcon className='m-1.5 text-red-600' />
        </div>
        <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
          <h3 className='text-lg font-medium leading-6 text-gray-900 sm:pr-8'>
            Fehler
          </h3>
          <div className='mt-2'>
            {errors.map((error, index) => (
              <p key={index} className='text-sm text-gray-500'>
                {error}
              </p>
            ))}
          </div>
        </div>
      </div>
      <DialogButtons className='mt-5 border-0 sm:mt-4'>
        <Button type='button' variant='destructive' onClick={onClose}>
          OK
        </Button>
      </DialogButtons>
    </DialogBase>
  );
};

type UseHandleResponseReturn = (
  response: unknown,
) => Promise<{success: boolean}>;

export const useHandleResponse = (): UseHandleResponseReturn => {
  const open = useOpenModal();

  const errorDisplay = useCallback(
    async (response: unknown) => {
      const errors: string[] = [];

      if (typeof response === 'object' && response !== null) {
        if ('error' in response && response.error) {
          errors.push(JSON.stringify(response.error));
        }

        if (
          'data' in response &&
          typeof response.data === 'object' &&
          response.data !== null
        ) {
          for (const operationResult of Object.values(response.data)) {
            if ('errors' in operationResult && operationResult.errors) {
              for (const error of operationResult.errors) {
                errors.push(error.message);
              }
            }
          }
        }
      }

      if (errors.length > 0) {
        await open<void>((submit) => (
          <ErrorDialog errors={errors} onClose={submit} />
        ));

        return {success: false};
      }

      return {success: true};
    },
    [open],
  );

  return errorDisplay;
};
