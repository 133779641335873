import {motion} from 'framer-motion';
import {type FC, useEffect, useState} from 'react';
import {variants, type VariantProp} from 'lib/variants';

const loaderVariants = variants(
  {
    light: 'text-white',
    dark: 'text-indigo-600',
  },
  'dark',
);

const sizeVariants = variants(
  {
    small: 'w-4 h-4',
    medium: 'w-6 h-6',
    large: 'w-8 h-8',
  },
  'medium',
);

type LoaderProps = VariantProp<typeof loaderVariants> &
  VariantProp<typeof sizeVariants, 'size'> & {
    delayed?: boolean;
    delay?: number;
    className?: string;
  };

export const Loader: FC<LoaderProps> = ({
  delayed = true,
  delay = 500,
  variant,
  size,
  className,
}) => {
  const [visible, setVisible] = useState(!delayed);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (delayed) {
      timeout = setTimeout(() => {
        setVisible(true);
      }, delay);
    }

    return () => clearTimeout(timeout);
  }, [delayed, delay]);

  if (!visible) return null;

  return (
    <motion.div
      style={{opacity: 0}}
      animate={{opacity: 1}}
      className={loaderVariants(variant, className)}
    >
      <svg
        viewBox='0 0 200 200'
        xmlns='http://www.w3.org/2000/svg'
        fill='currentColor'
        className={sizeVariants(size, 'animate-loader-spin opacity-70')}
      >
        <path d='m117.686 152.021 18.625 32.261C120.221 193.103 105.235 200 100 200c-5.235 0-20.221-6.897-36.311-15.718l18.625-32.261c8.001 4.215 15.003 7.271 17.686 7.271s9.685-3.056 17.686-7.271Zm73.46-41.327c-.406 18.346-1.926 34.772-4.543 39.306-2.618 4.534-16.084 14.063-31.769 23.587l-18.625-32.26c7.651-4.821 13.798-9.358 15.139-11.681 1.342-2.324 2.197-9.915 2.546-18.952h37.252Zm-145.04 0c.349 9.037 1.204 16.628 2.546 18.952 1.341 2.323 7.488 6.86 15.139 11.681l-18.625 32.26C29.481 164.063 16.015 154.534 13.397 150c-2.617-4.534-4.137-20.96-4.543-39.306h37.252Zm108.728-84.281c15.685 9.524 29.151 19.053 31.769 23.587 2.617 4.534 4.137 20.96 4.543 39.306h-37.252c-.349-9.037-1.204-16.628-2.546-18.952-1.341-2.323-7.488-6.86-15.139-11.681l18.625-32.26Zm-109.668 0 18.625 32.26c-7.651 4.821-13.798 9.358-15.139 11.681-1.342 2.324-2.197 9.915-2.546 18.952H8.854C9.26 70.96 10.78 54.534 13.397 50c2.618-4.534 16.084-14.063 31.769-23.587Zm18.523-10.695C79.779 6.897 94.765 0 100 0c5.235 0 20.221 6.897 36.311 15.718l-18.625 32.261c-8.001-4.215-15.003-7.271-17.686-7.271s-9.685 3.056-17.686 7.271L63.689 15.718Z' />
        <path
          d='M63.79132868 141.327276c5.79142552 3.674952 12.44355754 7.521116 18.5225427 10.694l-18.6252556 32.259896c-6.17669356-3.373648-12.51673325-7.030372-18.5225427-10.694l18.6252556-32.259896Zm72.52005555-125.608448c6.17669355 3.373648 12.51673325 7.030372 18.5225427 10.694l-18.6252556 32.259896c-5.79142553-3.674952-12.44355755-7.521116-18.5225427-10.694l18.6252556-32.259896Zm-.10281291 125.608448 18.6252556 32.259896c-6.01001024 3.662352-12.3468445 7.324628-18.5225427 10.694l-18.6252556-32.259896c6.07831313-3.178048 12.73525331-7.015884 18.5225427-10.694ZM63.68851577 15.718828l18.6252556 32.259896c-6.07831312 3.178048-12.7352533 7.015884-18.5225427 10.694l-18.6252556-32.259896c6.01001025-3.662352 12.3468445-7.324628 18.5225427-10.694ZM153.89466544 89.306h37.25041824c.16668288 7.036.16988832 14.355 0 21.388h-37.25041824c.28688688-6.853.29169504-14.537 0-21.388Zm-145.03974912 0h37.25041824c-.28688688 6.853-.29169504 14.537 0 21.388H8.85491632c-.16668288-7.036-.16988832-14.355 0-21.388Z'
          fillOpacity='0.17'
        />
      </svg>
    </motion.div>
  );
};
