import {type FC, useMemo} from 'react';
import {Button} from '../../components/button';
import {RichTextDisplay} from '../../components/forms/base-fields/rich-text-field';
import {ItemFilterEditor} from '../../lib/context/item-filter/item-filter-editor.tsx';
import {useItemFilterContext} from '../../lib/context/item-filter/item-filter.tsx';
import {useOpenGoalUpsertModal} from '../all-items/goal-upsert-modal/goal-upsert-modal.tsx';
import {useOpenTodoUpsertModal} from '../all-items/todo-upsert-modal/todo-upsert-modal.tsx';
import {
  type DoTodo,
  type ElaborateOnGoal,
  type TriageTodo,
  useDoTodoRecommendationLaterMutation,
  useElaborateOnGoalRecommendationLaterMutation,
  useRecommendationQuery,
  useTriageTodoRecommendationLaterMutation,
} from './recommendations.generated.ts';

export const DoTodoRecommendation: FC<{todo: DoTodo}> = ({todo}) => {
  const [, doLater] = useDoTodoRecommendationLaterMutation();
  const openTodoUpsertModal = useOpenTodoUpsertModal();

  return (
    <div className='rounded-lg bg-white p-4 text-gray-800 shadow-lg'>
      <h3 className='mb-2 text-lg font-semibold'>Do Todo</h3>
      <p className='mb-4'>
        <RichTextDisplay value={todo.content} />
      </p>
      <div className='flex justify-between'>
        <Button>Start working</Button>
        <Button onClick={() => doLater({input: {id: todo.id}})}>
          Do later
        </Button>
        <Button>Done</Button>
        <Button onClick={() => openTodoUpsertModal({id: todo.id})}>Edit</Button>
      </div>
    </div>
  );
};

export const ElaborateOnGoalRecommendation: FC<{goal: ElaborateOnGoal}> = ({
  goal,
}) => {
  const [, doLater] = useElaborateOnGoalRecommendationLaterMutation();
  const openGoalUpsertModal = useOpenGoalUpsertModal();
  const openTodoUpsertModal = useOpenTodoUpsertModal();

  return (
    <div className='rounded-lg bg-white p-4 text-gray-800 shadow-lg'>
      <h3 className='mb-2 text-lg font-semibold'>Elaborate on goal</h3>
      <p className='mb-4'>{goal.content}</p>
      <div className='flex justify-between'>
        <Button onClick={() => doLater({input: {id: goal.id}})}>
          Do later
        </Button>
        <Button onClick={() => openGoalUpsertModal({id: goal.id})}>Edit</Button>
        <Button onClick={() => openTodoUpsertModal({parentGoalId: goal.id})}>
          Add Todo
        </Button>
      </div>
    </div>
  );
};

export const TriageTodoRecommendation: FC<{todo: TriageTodo}> = ({todo}) => {
  const [, doLater] = useTriageTodoRecommendationLaterMutation();
  const openTodoUpsertModal = useOpenTodoUpsertModal();

  return (
    <div className='rounded-lg bg-white p-4 text-gray-800 shadow-lg'>
      <h3 className='mb-2 text-lg font-semibold'>Triage Todo</h3>
      <p className='mb-4'>
        <RichTextDisplay value={todo.content} />
      </p>
      <div className='flex justify-between'>
        <Button>Start working</Button>
        <Button onClick={() => doLater({input: {id: todo.id}})}>
          Do later
        </Button>
        <Button>Done</Button>
        <Button onClick={() => openTodoUpsertModal({id: todo.id})}>Edit</Button>
      </div>
    </div>
  );
};

export const Recommendations = () => {
  const itemFilterContext = useItemFilterContext();
  const [{data}] = useRecommendationQuery({
    variables: {relatedTo: itemFilterContext.goalId},
  });

  const displayRecommendation = useMemo(() => {
    switch (data?.my.topRecommendation?.__typename) {
      case 'DoTodoRecommendation':
        return <DoTodoRecommendation todo={data.my.topRecommendation.todo} />;
      case 'ElaborateOnGoalRecommendation':
        return (
          <ElaborateOnGoalRecommendation
            goal={data.my.topRecommendation.goal}
          />
        );
      case 'TriageTodoRecommendation':
        return (
          <TriageTodoRecommendation todo={data.my.topRecommendation.todo} />
        );
    }
  }, [data?.my.topRecommendation]);

  return (
    <div className='mx-auto my-8 max-w-2xl'>
      <ItemFilterEditor className='mb-4' doNotAskCompletionStatus={true} />
      {displayRecommendation}
    </div>
  );
};
