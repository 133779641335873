import * as Types from '../../../../__generated__/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetGoalsQueryVariables = Types.Exact<{[key: string]: never}>;

export type GetGoalsQuery = {__typename?: 'Query'; my: {__typename?: 'User'; allGoals: Array<Goal>}};

export type Goal = {__typename?: 'Goal'; id: string; content: string; parentId?: string | null};

export const GetGoalsDocument = gql`
  query GetGoals {
    my {
      allGoals @export(exportName: "Goal") {
        id
        content
        parentId
      }
    }
  }
`;

export function useGetGoalsQuery(options?: Omit<Urql.UseQueryArgs<GetGoalsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetGoalsQuery, GetGoalsQueryVariables>({query: GetGoalsDocument, ...options});
}
