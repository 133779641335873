import {XMarkIcon} from '@heroicons/react/20/solid';
import FocusTrap from 'focus-trap-react';
import {
  type FC,
  type ForwardedRef,
  forwardRef,
  type PropsWithChildren,
  type ReactNode,
} from 'react';
import {twMerge} from 'tailwind-merge';
import {Button} from 'components/button';
import {type VariantProp, variants} from 'lib/variants';

const sizeVariants = variants(
  {
    md: 'md:max-w-md',
    lg: 'md:max-w-lg',
    xl: 'md:max-w-xl',
    '2xl': 'md:max-w-2xl',
    '3xl': 'md:max-w-3xl',
    '4xl': 'md:max-w-4xl',
    '5xl': 'md:max-w-5xl',
    '6xl': 'md:max-w-6xl',
    '7xl': 'md:max-w-7xl',
  },
  'lg',
);

type DialogBaseProps = VariantProp<typeof sizeVariants, 'size'> & {
  title?: string | ReactNode;
  onClose?: () => void;
  className?: string;
  headerClassName?: string;
  innerRef?: ForwardedRef<HTMLDivElement>;
};

const InnerDialogBase: FC<PropsWithChildren<DialogBaseProps>> = ({
  title,
  onClose,
  children,
  size,
  className,
  headerClassName,
  innerRef,
}) => {
  const hasHeader = title || onClose;

  return (
    <div
      ref={innerRef}
      className='grid h-full items-center justify-center overflow-y-auto'
    >
      <FocusTrap
        focusTrapOptions={{
          allowOutsideClick: true,
          escapeDeactivates: false,
          preventScroll: true,
        }}
      >
        <div
          className={sizeVariants(
            size,
            'relative my-8 w-screen overflow-y-visible rounded-lg bg-white px-5 pb-4 pt-5 shadow-xl sm:max-w-full',
            hasHeader ? 'pt-0' : '',
            className,
          )}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          {hasHeader && (
            <header
              className={twMerge(
                'relative pb-2 pt-3 text-lg font-semibold text-gray-800',
                !title && 'h-12',
                headerClassName,
              )}
            >
              {title}
              {onClose && (
                <div className='absolute -right-5 top-0 z-10 hidden h-full sm:block'>
                  <Button
                    type='button'
                    variant='tertiary'
                    onClick={onClose}
                    className='h-full px-5 py-0 text-gray-400 hover:text-gray-500'
                    tabIndex={-1}
                  >
                    <span className='sr-only'>Close</span>
                    <XMarkIcon className='h-5 w-5' />
                  </Button>
                </div>
              )}
            </header>
          )}
          {children}
        </div>
      </FocusTrap>
    </div>
  );
};

export const DialogBase = forwardRef<
  HTMLDivElement,
  PropsWithChildren<DialogBaseProps>
>((props, ref) => <InnerDialogBase {...props} innerRef={ref} />);
DialogBase.displayName = 'DialogBase';

type DialogButtonsProps = {
  className?: string;
};

export const DialogButtons: FC<PropsWithChildren<DialogButtonsProps>> = ({
  children,
  className,
}) => {
  return (
    <div
      className={twMerge(
        'mt-4 flex justify-end gap-3 border-t border-gray-200 pt-3',
        className,
      )}
    >
      {children}
    </div>
  );
};
