import {type FC, type PropsWithChildren} from 'react';
import {twMerge} from 'tailwind-merge';

export const PageContainer: FC<
  PropsWithChildren<{column?: boolean; className?: string}>
> = ({children, column, className}) => (
  <div
    className={twMerge(
      'mx-auto flex min-h-full px-4 py-6 lg:px-8 2xl:px-10',
      column ? 'flex-col' : 'flex-row',
      className,
    )}
  >
    {children}
  </div>
);
