import {type FormBuilder} from '@atmina/formbuilder';

// export type FieldRules<T> = RegisterOptions<{__: T; b: T}, T extends Primitive | BrowserNativeObject ? '__' : '__'>;

export type FieldProps<T = string> = {
  label: string;
  on: FormBuilder<T>;
  className?: string;
  helperText?: string;
  // rules?: FieldRules<T> | null;
  type?: string;
  monospace?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
};

// RHF's `valueAsNumber` turns null into 0, but we don't want that.
// https://github.com/react-hook-form/react-hook-form/issues/6287
// We also abuse this hook to ensure decimal separators are replaced if needed.
export const setValueAsNumber = (value: unknown) => {
  if (value === '' || value === null) return null;

  const maybeNumber = Number(value);
  if (!isNaN(maybeNumber)) return maybeNumber;

  const valueString = value + '';
  const updatedSeparator = valueString.includes(',') ? '.' : ',';
  const replacedValue = valueString.replace(/[,.]/g, updatedSeparator);

  const maybeNumberReplaced = Number(replacedValue);

  return isNaN(maybeNumberReplaced) ? null : maybeNumberReplaced;
};

export const getFieldError = (on: FormBuilder<unknown>) => {
  const errorMap: Record<string, string> = {
    required: 'Dieses Feld ist ein Pflichtfeld.',
  };

  const {errors} = on.$useState();

  return errors
    ? errors.message || (errors.type && errorMap[errors.type]) || true
    : undefined;
};
