import {IntrospectionQuery} from 'graphql';
export default {
  __schema: {
    queryType: {
      name: 'Query',
    },
    mutationType: {
      name: 'Mutation',
    },
    subscriptionType: null,
    types: [
      {
        kind: 'SCALAR',
        name: 'Boolean',
      },
      {
        kind: 'OBJECT',
        name: 'DoTodoRecommendation',
        fields: [
          {
            name: 'baseImportance',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'TodoId',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'importance',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'todo',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Todo',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Recommendation',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'DoTodoRecommendationLaterPayload',
        fields: [
          {
            name: 'success',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'ElaborateOnGoalRecommendation',
        fields: [
          {
            name: 'baseImportance',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'goal',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Goal',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'GoalId',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'importance',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Recommendation',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'ElaborateOnGoalRecommendationLaterPayload',
        fields: [
          {
            name: 'success',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'INTERFACE',
        name: 'Error',
        fields: [
          {
            name: 'message',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'GoalTreeMustBeValidTree',
          },
        ],
      },
      {
        kind: 'SCALAR',
        name: 'Float',
      },
      {
        kind: 'OBJECT',
        name: 'Goal',
        fields: [
          {
            name: 'ancestors',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Goal',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'content',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'GoalId',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'parentId',
            type: {
              kind: 'SCALAR',
              name: 'GoalId',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'GoalCreatePayload',
        fields: [
          {
            name: 'success',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'SCALAR',
        name: 'GoalId',
      },
      {
        kind: 'OBJECT',
        name: 'GoalTreeMustBeValidTree',
        fields: [
          {
            name: 'message',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Error',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'GoalUpdateError',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'GoalTreeMustBeValidTree',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'GoalUpdatePayload',
        fields: [
          {
            name: 'errors',
            type: {
              kind: 'LIST',
              ofType: {
                kind: 'NON_NULL',
                ofType: {
                  kind: 'UNION',
                  name: 'GoalUpdateError',
                  ofType: null,
                },
              },
            },
            args: [],
          },
          {
            name: 'success',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'LoginPayload',
        fields: [
          {
            name: 'success',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'LogoutPayload',
        fields: [
          {
            name: 'success',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Mutation',
        fields: [
          {
            name: 'doTodoRecommendationLater',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'DoTodoRecommendationLaterPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'elaborateOnGoalRecommendationLater',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'ElaborateOnGoalRecommendationLaterPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'goalCreate',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'GoalCreatePayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'create',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'goalUpdate',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'GoalUpdatePayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'update',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'login',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'LoginPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'logout',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'LogoutPayload',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'todoCreate',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'TodoCreatePayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'create',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'todoUpdate',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'TodoUpdatePayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'update',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'triageTodoRecommendationLater',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'TriageTodoRecommendationLaterPayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'input',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
          {
            name: 'userChange',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'UserChangePayload',
                ofType: null,
              },
            },
            args: [
              {
                name: 'change',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'Any',
                  },
                },
              },
            ],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'Query',
        fields: [
          {
            name: 'my',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'User',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'user',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'User',
                ofType: null,
              },
            },
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'UserId',
                    ofType: null,
                  },
                },
              },
            ],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'INTERFACE',
        name: 'Recommendation',
        fields: [
          {
            name: 'baseImportance',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'importance',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'DoTodoRecommendation',
          },
          {
            kind: 'OBJECT',
            name: 'ElaborateOnGoalRecommendation',
          },
          {
            kind: 'OBJECT',
            name: 'TriageTodoRecommendation',
          },
        ],
      },
      {
        kind: 'SCALAR',
        name: 'String',
      },
      {
        kind: 'OBJECT',
        name: 'Todo',
        fields: [
          {
            name: 'content',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'done',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'goalAncestors',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Goal',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'TodoId',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'parentGoalId',
            type: {
              kind: 'SCALAR',
              name: 'GoalId',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'parentTodoId',
            type: {
              kind: 'SCALAR',
              name: 'TodoId',
              ofType: null,
            },
            args: [],
          },
          {
            name: 'todoAncestors',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Todo',
                    ofType: null,
                  },
                },
              },
            },
            args: [],
          },
          {
            name: 'userId',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UserId',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'TodoCreatePayload',
        fields: [
          {
            name: 'success',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'SCALAR',
        name: 'TodoId',
      },
      {
        kind: 'OBJECT',
        name: 'TodoUpdatePayload',
        fields: [
          {
            name: 'success',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'TriageTodoRecommendation',
        fields: [
          {
            name: 'baseImportance',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'TodoId',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'importance',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'Float',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'todo',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Todo',
                ofType: null,
              },
            },
            args: [],
          },
        ],
        interfaces: [
          {
            kind: 'INTERFACE',
            name: 'Recommendation',
          },
        ],
      },
      {
        kind: 'OBJECT',
        name: 'TriageTodoRecommendationLaterPayload',
        fields: [
          {
            name: 'success',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'User',
        fields: [
          {
            name: 'allGoals',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Goal',
                    ofType: null,
                  },
                },
              },
            },
            args: [
              {
                name: 'relatedTo',
                type: {
                  kind: 'SCALAR',
                  name: 'GoalId',
                  ofType: null,
                },
              },
            ],
          },
          {
            name: 'allTodos',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'OBJECT',
                    name: 'Todo',
                    ofType: null,
                  },
                },
              },
            },
            args: [
              {
                name: 'hideCompleted',
                type: {
                  kind: 'SCALAR',
                  name: 'Boolean',
                  ofType: null,
                },
              },
              {
                name: 'relatedTo',
                type: {
                  kind: 'SCALAR',
                  name: 'GoalId',
                  ofType: null,
                },
              },
            ],
          },
          {
            name: 'email',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'goal',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Goal',
                ofType: null,
              },
            },
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'GoalId',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'id',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'UserId',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'name',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'SCALAR',
                name: 'String',
                ofType: null,
              },
            },
            args: [],
          },
          {
            name: 'recommendations',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'LIST',
                ofType: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'INTERFACE',
                    name: 'Recommendation',
                    ofType: null,
                  },
                },
              },
            },
            args: [
              {
                name: 'relatedTo',
                type: {
                  kind: 'SCALAR',
                  name: 'GoalId',
                  ofType: null,
                },
              },
            ],
          },
          {
            name: 'todo',
            type: {
              kind: 'NON_NULL',
              ofType: {
                kind: 'OBJECT',
                name: 'Todo',
                ofType: null,
              },
            },
            args: [
              {
                name: 'id',
                type: {
                  kind: 'NON_NULL',
                  ofType: {
                    kind: 'SCALAR',
                    name: 'TodoId',
                    ofType: null,
                  },
                },
              },
            ],
          },
          {
            name: 'topRecommendation',
            type: {
              kind: 'INTERFACE',
              name: 'Recommendation',
              ofType: null,
            },
            args: [
              {
                name: 'relatedTo',
                type: {
                  kind: 'SCALAR',
                  name: 'GoalId',
                  ofType: null,
                },
              },
            ],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'OBJECT',
        name: 'UserChangePayload',
        fields: [
          {
            name: 'success',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
              ofType: null,
            },
            args: [],
          },
        ],
        interfaces: [],
      },
      {
        kind: 'SCALAR',
        name: 'UserId',
      },
      {
        kind: 'SCALAR',
        name: 'Any',
      },
    ],
    directives: [],
  },
} as unknown as IntrospectionQuery;
