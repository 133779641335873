import {useFormBuilder} from '@atmina/formbuilder';
import {type FC, useCallback, useEffect, useMemo} from 'react';

import {type SubmitHandler} from 'react-hook-form';
import {Button} from 'components/button';
import {DialogBase, DialogButtons} from 'components/dialog-base/dialog-base';
import {InputFormField} from 'components/forms/base-fields';
import Form from 'components/forms/form';
import {useLoadingSignal} from 'lib/hooks/use-loading-signal';
import {GoalSelectField} from '../../../components/forms/domain-fields/goal-select/goal-select.tsx';
import {useOpenModal} from '../../../lib/context/modal/modal.tsx';
import {useHandleResponse} from '../../../lib/hooks/dialog/use-handle-response.tsx';
import {type Goal} from '../all-items.generated.ts';
import {
  type GoalCreateInput,
  type GoalUpdateInput,
  useCreateGoalMutation,
  useGetGoalQuery,
  useUpdateGoalMutation,
} from './goal-upsert-modal.generated.ts';

type GoalUpsertFormData = GoalCreateInput | GoalUpdateInput;

const emptyState = {
  content: '',
  userId: '00000000-0000-0000-0000-000000000000',
  parentId: null,
} satisfies GoalUpsertFormData;

export const GoalUpsertModal: FC<{close: () => void; goal?: Partial<Goal>}> = ({
  close,
  goal,
}) => {
  const handleResponse = useHandleResponse();
  const builder = useFormBuilder<GoalUpsertFormData>();
  const loadingSignal = useLoadingSignal();

  useEffect(() => {
    if (!goal?.id) {
      builder.reset({...emptyState, ...goal});
    }

    // builder causes infinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goal?.id]);

  const [{data, fetching: getGoalQuery}] = useGetGoalQuery({
    variables: {id: goal?.id ?? ''},
    pause: !goal?.id,
  });

  useEffect(() => {
    if (data?.my.goal && goal?.id) {
      const {id, __typename: unused, ...form} = data.my.goal;
      builder.reset(form);
    }

    // builder causes infinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.my.goal, goal?.id]);

  const [{fetching: createGoalMutate}, createGoal] = useCreateGoalMutation();
  const [{fetching: updateGoalMutate}, updateGoal] = useUpdateGoalMutation();

  useEffect(
    () =>
      loadingSignal.setLoading(
        createGoalMutate || updateGoalMutate || getGoalQuery,
      ),
    [loadingSignal, createGoalMutate, updateGoalMutate, getGoalQuery],
  );

  const onSubmit: SubmitHandler<GoalUpsertFormData> = useCallback(
    async (form) => {
      const response = goal?.id
        ? await updateGoal({input: {...form, id: goal.id}})
        : await createGoal({
            input: {...form, userId: '00000000-0000-0000-0000-000000000000'},
          });

      if ((await handleResponse(response)).success) {
        close();
      }
    },
    [goal?.id, updateGoal, createGoal, handleResponse, close],
  );

  return (
    <DialogBase title={goal?.id ? 'Ziel bearbeiten' : 'Neues Ziel erstellen'}>
      <Form builder={builder} className='block space-y-6' onSubmit={onSubmit}>
        <InputFormField label='Name' on={builder.fields.content} />
        <GoalSelectField on={builder.fields.parentId} label='Gehört zu Ziel' />

        <DialogButtons>
          <Button
            type='button'
            loadingSignal={loadingSignal}
            loadingText='Abbrechen'
            onClick={close}
            variant='secondary'
          >
            Abbrechen
          </Button>
          <Button
            type='submit'
            loadingSignal={loadingSignal}
            loadingText='Speichern'
          >
            Speichern
          </Button>
        </DialogButtons>
      </Form>
    </DialogBase>
  );
};

export const useOpenGoalUpsertModal = () => {
  const open = useOpenModal();

  return useMemo(() => {
    return async (goal?: Partial<Goal>) => {
      await open((_, close) => <GoalUpsertModal goal={goal} close={close} />);
    };
  }, [open]);
};
