import * as Types from '../../../../__generated__/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type GetTodosQueryVariables = Types.Exact<{[key: string]: never}>;

export type GetTodosQuery = {__typename?: 'Query'; my: {__typename?: 'User'; allTodos: Array<Todo>}};

export type Todo = {__typename?: 'Todo'; id: string; content: string};

export const GetTodosDocument = gql`
  query GetTodos {
    my {
      allTodos @export(exportName: "Todo") {
        id
        content
      }
    }
  }
`;

export function useGetTodosQuery(options?: Omit<Urql.UseQueryArgs<GetTodosQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTodosQuery, GetTodosQueryVariables>({query: GetTodosDocument, ...options});
}
