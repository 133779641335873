import {createContext, useContext, useEffect} from 'react';

export type PreventModalCloseListener = () => Promise<boolean>;

export type PreventModalCloseContextValue = {
  addPreventListener: (listener: PreventModalCloseListener) => () => void;
};

export const PreventModalCloseContext =
  createContext<PreventModalCloseContextValue | null>(null);

export const usePreventModalCloseContext = () => {
  const context = useContext(PreventModalCloseContext);

  if (!context) {
    throw new Error(
      `usePreventModalCloseContext can only be used inside a <PreventModalClose /> Component`,
    );
  }

  return context;
};

export const usePreventModalClose = (callback: () => Promise<boolean>) => {
  const {addPreventListener} = usePreventModalCloseContext();

  useEffect(() => addPreventListener(callback), [addPreventListener, callback]);
};

export const closePreventedBySome = async (
  preventCloseListeners: Set<PreventModalCloseListener>,
) => {
  let prevented = false;
  const listeners = Array.from(preventCloseListeners.values());
  for (const listener of listeners) {
    const prevent = await listener();
    if (prevent) {
      prevented = true;
    }
  }

  return prevented;
};
