import {type FormBuilder} from '@atmina/formbuilder';
import {useRegisterActions} from 'kbar';
import {type FC, useCallback, useMemo} from 'react';
import {type SingleValue} from 'react-select';
import Creatable from 'react-select/creatable';
import {twMerge} from 'tailwind-merge';
import {type Goal, useGetGoalsQuery} from './goal-select.generated.ts';

type GoalOption = {
  id: string;
  content: string;
};

export const GoalSelectField: FC<{
  on: FormBuilder<string | undefined>;
  label: string;
  className?: string;
  registerAsActions?: boolean;
}> = ({on, label, className, registerAsActions}) => {
  const [{data}] = useGetGoalsQuery();

  const {onChange} = on.$useController().field;
  const currentGoalId = on.$useWatch();

  const handleChange = useCallback(
    (value: SingleValue<Goal>) => {
      onChange(value?.id);
    },
    [onChange],
  );

  const options = useMemo(() => {
    if (!data) return undefined;

    const goals: GoalOption[] = [];

    function add(parent: Goal | null, indent: number) {
      if (!data) return;

      if (!parent) {
        for (const goal of data.my.allGoals.filter((goal) => !goal.parentId)) {
          goals.push(goal);
          add(goal, indent + 1);
        }
      } else {
        for (const goal of data.my.allGoals.filter(
          (goal) => goal.parentId === parent.id,
        )) {
          goals.push({
            ...goal,
            content: `${'\u00a0'.repeat(indent * 2)}${goal.content}`,
          });
          add(goal, indent + 1);
        }
      }
    }

    add(null, 0);

    return goals;
  }, [data]);

  useRegisterActions(
    useMemo(() => {
      if (!options || !registerAsActions) return [];
      return [
        {
          id: 'clear',
          name: 'Clear',
          section: 'Filter',
          perform: () => onChange(undefined),
        },
        ...options.map((goal) => ({
          id: goal.id,
          name: goal.content,
          section: 'Filter',
          perform: () => handleChange(goal),
        })),
      ];
    }, [options, registerAsActions, onChange, handleChange]),
    [options, registerAsActions, onChange, handleChange],
  );

  if (!options) return null;

  return (
    <label className={twMerge('block', className)}>
      <span className='block text-sm font-medium leading-5 text-gray-700'>
        {label}
      </span>
      <Creatable
        className='z-60'
        menuPortalTarget={document.querySelector('body')}
        styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
        value={options?.find((br) => br.id === currentGoalId)}
        isClearable
        isValidNewOption={() => false}
        options={options}
        getOptionLabel={(r) => r.content}
        getOptionValue={(r) => r.id}
        onChange={handleChange}
      />
    </label>
  );
};
