import {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useLogoutMutation,
  useMyQuery,
  useLoginMutation,
  type UserFragment,
} from 'lib/context/auth/auth.generated';
import {NoContextProvidedError} from 'lib/context/helpers';
import {reconnectSubscriptionClient} from 'lib/context/urql';

type AuthContextData = {
  isLoggedIn: boolean;
  user: UserFragment | null;
  refreshUser: () => void;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
};

export const AuthContext = createContext<AuthContextData | null>(null);

export const useAuth = () => {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw new NoContextProvidedError('AuthContext');
  }

  return auth;
};

const hasCookie = () => document.cookie.includes('DoLoginShadow');

export const AuthProvider: FC<PropsWithChildren> = ({children}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => hasCookie());
  const [user, setUser] = useState<UserFragment | null>(null);
  const [{data: myData}, refetchMyQuery] = useMyQuery({pause: !isLoggedIn});
  const [, mutateLogin] = useLoginMutation();
  const [, mutateLogout] = useLogoutMutation();

  useEffect(() => {
    if (!isLoggedIn) {
      return setUser(null);
    }

    if (myData?.my) {
      setUser(myData.my);
    } else {
      setUser(null);
    }
  }, [isLoggedIn, myData]);

  const refreshUser = useCallback(() => refetchMyQuery(), [refetchMyQuery]);

  useEffect(() => {
    if (isLoggedIn) {
      refreshUser();
    }
  }, [isLoggedIn, refreshUser]);

  const login = useCallback(
    async (email: string, password: string) => {
      const result = await mutateLogin({input: {email, password}});
      const success = result.data?.login.success ?? false;

      reconnectSubscriptionClient();
      setIsLoggedIn(success);

      return success;
    },
    [mutateLogin],
  );

  const logout = useCallback(async () => {
    await mutateLogout({});
    reconnectSubscriptionClient();
    setIsLoggedIn(false);
  }, [mutateLogout]);

  const contextValue = useMemo<AuthContextData>(
    () => ({
      isLoggedIn,
      user,
      refreshUser,
      login,
      logout,
    }),
    [isLoggedIn, logout, login, refreshUser, user],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
