import {type FormBuilder, type RegisterOptions} from '@atmina/formbuilder';
import {type ComponentPropsWithRef, type FC, forwardRef} from 'react';
import {type UseFormRegisterReturn} from 'react-hook-form';
import {twMerge} from 'tailwind-merge';

export type SwitchFieldProps = {label: string; switchClasses?: string} & Omit<
  ComponentPropsWithRef<'input'>,
  'type'
>;
export type SwitchFormFieldProps = {
  on: FormBuilder<boolean>;
  rules?: RegisterOptions<boolean>;
} & Omit<SwitchFieldProps, keyof UseFormRegisterReturn>;

export const SwitchField = forwardRef<HTMLInputElement, SwitchFieldProps>(
  ({label, switchClasses, ...props}, ref) => {
    return (
      <label className='flex items-center gap-3'>
        <span
          className='relative inline-flex h-5 cursor-pointer select-none items-center'
          role='switch'
        >
          <input
            type='checkbox'
            {...props}
            readOnly={typeof props.checked === 'boolean' && !props.onChange}
            className='peer hidden'
            ref={ref}
          />
          <div
            className={twMerge(
              "peer h-4 w-7 rounded-full bg-gray-200 transition-colors after:absolute after:left-[-5px] after:top-0 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:shadow-md after:transition-all after:content-[''] peer-checked:bg-indigo-600 peer-checked:after:translate-x-full peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-300 peer-disabled:cursor-default peer-disabled:opacity-50",
              switchClasses,
            )}
          />
        </span>

        <span className='text-sm font-medium'>{label}</span>
      </label>
    );
  },
);
SwitchField.displayName = 'SwitchField';

export const SwitchFormField: FC<SwitchFormFieldProps> = ({
  on,
  rules,
  ...props
}) => {
  return <SwitchField {...on(rules)} {...props} />;
};
