import {
  type FC,
  type ButtonHTMLAttributes,
  type PropsWithChildren,
  forwardRef,
} from 'react';
import {Loader} from 'components/loader';
import {
  type LoadingSignal,
  useLoadingSignal,
  useLoadingSignalState,
} from 'lib/hooks/use-loading-signal';
import {variants, type VariantProp} from 'lib/variants';

const baseClasses =
  'flex items-center justify-center rounded-md border focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2';

const buttonVariants = variants(
  {
    primary: `${baseClasses} border-transparent bg-indigo-800 font-medium text-white shadow-sm hover:bg-indigo-700`,
    'primary-light': `${baseClasses} border-transparent bg-indigo-800/10 font-medium text-indigo-800 hover:bg-indigo-800/20`,
    secondary: `${baseClasses} border-gray-300 bg-white font-medium text-gray-700 shadow-sm hover:bg-gray-50`,
    'secondary-light': `${baseClasses} border-transparent bg-gray-50 font-medium text-gray-700 hover:bg-gray-100`,
    tertiary: `${baseClasses} border-transparent bg-transparent font-medium text-indigo-800 hover:text-indigo-700`,
    tiny: `${baseClasses} bg-gray-100 border-0`,
    destructive: `${baseClasses} border-transparent bg-red-600 font-medium text-white shadow-sm hover:bg-red-700`,
    'destructive-light': `${baseClasses} border-transparent bg-red-600/10 font-medium text-red-600 hover:bg-red-600/20`,
  },
  'primary',
);

const sizeVariants = variants(
  {
    small: ' py-1 px-2 text-sm',
    medium: 'py-2 px-4 text-sm',
    large: ' py-4 px-6 text-md',
  },
  'medium',
);

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProp<typeof buttonVariants> &
  VariantProp<typeof sizeVariants, 'size'> & {
    loadingSignal?: LoadingSignal;
    loadingText?: string;
  };

const Button: FC<PropsWithChildren<ButtonProps>> = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ButtonProps>
>(
  (
    {
      children,
      className,
      variant,
      size,
      loadingSignal,
      loadingText,
      onClick,
      disabled,
      ...buttonProps
    },
    ref,
  ) => {
    const signal = useLoadingSignal(loadingSignal);
    const loading = useLoadingSignalState(signal);

    return (
      <button
        ref={ref}
        className={buttonVariants(
          variant,
          'disabled:pointer-events-none disabled:cursor-default disabled:opacity-50',
          sizeVariants(size),
          className,
        )}
        disabled={disabled || loading}
        onClick={onClick}
        {...buttonProps}
      >
        {loading ? (
          <div className='flex items-center'>
            <Loader size='small' variant='light' delayed={false} />
            {loadingText && <span className='ml-1'>{loadingText}</span>}
          </div>
        ) : (
          children
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';

export {Button};
