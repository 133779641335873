import * as Types from '../../__generated__/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DoTodoRecommendationLaterInput = {
  id: string;
};

export type ElaborateOnGoalRecommendationLaterInput = {
  id: string;
};

export type TriageTodoRecommendationLaterInput = {
  id: string;
};

export type RecommendationQueryVariables = Types.Exact<{
  relatedTo?: Types.Maybe<string>;
}>;

export type RecommendationQuery = {
  __typename?: 'Query';
  my: {
    __typename?: 'User';
    topRecommendation?:
      | {__typename: 'DoTodoRecommendation'; todo: DoTodo}
      | {__typename: 'ElaborateOnGoalRecommendation'; goal: ElaborateOnGoal}
      | {__typename: 'TriageTodoRecommendation'; todo: TriageTodo}
      | null;
  };
};

export type DoTodo = {
  __typename?: 'Todo';
  id: string;
  content: string;
  todoAncestors: Array<{__typename?: 'Todo'; id: string; content: string}>;
  goalAncestors: Array<{__typename?: 'Goal'; id: string; content: string}>;
};

export type ElaborateOnGoal = {
  __typename?: 'Goal';
  id: string;
  content: string;
  ancestors: Array<{__typename?: 'Goal'; id: string; content: string}>;
};

export type TriageTodo = {
  __typename?: 'Todo';
  id: string;
  content: string;
  todoAncestors: Array<{__typename?: 'Todo'; id: string; content: string}>;
  goalAncestors: Array<{__typename?: 'Goal'; id: string; content: string}>;
};

export type TriageTodoRecommendationLaterMutationVariables = Types.Exact<{
  input: TriageTodoRecommendationLaterInput;
}>;

export type TriageTodoRecommendationLaterMutation = {
  __typename?: 'Mutation';
  triageTodoRecommendationLater: {__typename?: 'TriageTodoRecommendationLaterPayload'; success?: boolean | null};
};

export type DoTodoRecommendationLaterMutationVariables = Types.Exact<{
  input: DoTodoRecommendationLaterInput;
}>;

export type DoTodoRecommendationLaterMutation = {
  __typename?: 'Mutation';
  doTodoRecommendationLater: {__typename?: 'DoTodoRecommendationLaterPayload'; success?: boolean | null};
};

export type ElaborateOnGoalRecommendationLaterMutationVariables = Types.Exact<{
  input: ElaborateOnGoalRecommendationLaterInput;
}>;

export type ElaborateOnGoalRecommendationLaterMutation = {
  __typename?: 'Mutation';
  elaborateOnGoalRecommendationLater: {
    __typename?: 'ElaborateOnGoalRecommendationLaterPayload';
    success?: boolean | null;
  };
};

export const RecommendationDocument = gql`
  query Recommendation($relatedTo: GoalId) {
    my {
      topRecommendation(relatedTo: $relatedTo) {
        __typename
        ... on DoTodoRecommendation {
          todo @export(exportName: "DoTodo") {
            id
            content
            todoAncestors {
              id
              content
            }
            goalAncestors {
              id
              content
            }
          }
        }
        ... on ElaborateOnGoalRecommendation {
          goal @export(exportName: "ElaborateOnGoal") {
            id
            content
            ancestors {
              id
              content
            }
          }
        }
        ... on TriageTodoRecommendation {
          todo @export(exportName: "TriageTodo") {
            id
            content
            todoAncestors {
              id
              content
            }
            goalAncestors {
              id
              content
            }
          }
        }
      }
    }
  }
`;

export function useRecommendationQuery(options?: Omit<Urql.UseQueryArgs<RecommendationQueryVariables>, 'query'>) {
  return Urql.useQuery<RecommendationQuery, RecommendationQueryVariables>({query: RecommendationDocument, ...options});
}
export const TriageTodoRecommendationLaterDocument = gql`
  mutation TriageTodoRecommendationLater($input: TriageTodoRecommendationLaterInput!) {
    triageTodoRecommendationLater(input: $input) {
      success
    }
  }
`;

export function useTriageTodoRecommendationLaterMutation() {
  return Urql.useMutation<TriageTodoRecommendationLaterMutation, TriageTodoRecommendationLaterMutationVariables>(
    TriageTodoRecommendationLaterDocument,
  );
}
export const DoTodoRecommendationLaterDocument = gql`
  mutation DoTodoRecommendationLater($input: DoTodoRecommendationLaterInput!) {
    doTodoRecommendationLater(input: $input) {
      success
    }
  }
`;

export function useDoTodoRecommendationLaterMutation() {
  return Urql.useMutation<DoTodoRecommendationLaterMutation, DoTodoRecommendationLaterMutationVariables>(
    DoTodoRecommendationLaterDocument,
  );
}
export const ElaborateOnGoalRecommendationLaterDocument = gql`
  mutation ElaborateOnGoalRecommendationLater($input: ElaborateOnGoalRecommendationLaterInput!) {
    elaborateOnGoalRecommendationLater(input: $input) {
      success
    }
  }
`;

export function useElaborateOnGoalRecommendationLaterMutation() {
  return Urql.useMutation<
    ElaborateOnGoalRecommendationLaterMutation,
    ElaborateOnGoalRecommendationLaterMutationVariables
  >(ElaborateOnGoalRecommendationLaterDocument);
}
