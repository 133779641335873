import {useFormBuilder} from '@atmina/formbuilder';
import {type FC, useCallback, useEffect, useMemo} from 'react';
import {type SubmitHandler} from 'react-hook-form';
import {Button} from 'components/button';
import {DialogBase, DialogButtons} from 'components/dialog-base/dialog-base';
import {CheckboxFormField} from 'components/forms/base-fields';
import Form from 'components/forms/form';
import {useLoadingSignal} from 'lib/hooks/use-loading-signal';
import {RichTextFormField} from '../../../components/forms/base-fields/rich-text-field';
import {GoalSelectField} from '../../../components/forms/domain-fields/goal-select/goal-select.tsx';
import {TodoSelectField} from '../../../components/forms/domain-fields/todo-select/todo-select.tsx';
import {useOpenModal} from '../../../lib/context/modal/modal.tsx';
import {type Todo} from '../all-items.generated.ts';
import {
  useCreateTodoMutation,
  useGetTodoQuery,
  useUpdateTodoMutation,
} from './todo-upsert-modal.generated.ts';

type TodoUpsertFormData = {
  userId: string;
  content: string;
  done: boolean;
  parentGoalId?: string | null;
  parentTodoId?: string | null;
};

const emptyState = {
  content: '',
  userId: '00000000-0000-0000-0000-000000000000',
  parentGoalId: null,
  parentTodoId: null,
  done: false,
} satisfies TodoUpsertFormData;

export const TodoUpsertModal: FC<{close: () => void; todo?: Partial<Todo>}> = ({
  close,
  todo,
}) => {
  const builder = useFormBuilder<TodoUpsertFormData>();
  const loadingSignal = useLoadingSignal();

  useEffect(() => {
    if (!todo?.id) {
      builder.reset({...emptyState, ...todo});
    }

    // builder causes infinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todo?.id]);

  const [{data, fetching: getTodoQuery}] = useGetTodoQuery({
    variables: {id: todo?.id ?? ''},
    pause: !todo?.id,
  });

  useEffect(() => {
    if (data?.my.todo && todo?.id) {
      const {id, __typename: unused, ...form} = data.my.todo;
      builder.reset(form);
    }

    // builder causes infinite re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.my.todo, todo?.id]);

  const [{fetching: createTodoMutate}, createTodo] = useCreateTodoMutation();
  const [{fetching: updateTodoMutate}, updateTodo] = useUpdateTodoMutation();

  useEffect(
    () =>
      loadingSignal.setLoading(
        createTodoMutate || updateTodoMutate || getTodoQuery,
      ),
    [loadingSignal, createTodoMutate, updateTodoMutate, getTodoQuery],
  );

  const onSubmit: SubmitHandler<TodoUpsertFormData> = useCallback(
    async (form) => {
      if (todo?.id)
        await updateTodo({
          input: {...form, id: todo.id},
        });
      else
        await createTodo({
          input: {...form, userId: '00000000-0000-0000-0000-000000000000'},
        });

      close();
    },
    [todo?.id, updateTodo, createTodo, close],
  );

  const goalId = builder.fields.parentGoalId.$useWatch();
  const todoId = builder.fields.parentTodoId.$useWatch();

  useEffect(() => {
    if (goalId) {
      builder.fields.parentTodoId.$setValue(null);
    } else if (todoId) {
      builder.fields.parentGoalId.$setValue(null);
    }
  }, [
    builder.fields.parentGoalId,
    builder.fields.parentTodoId,
    goalId,
    todoId,
  ]);

  return (
    <DialogBase
      title={todo?.id ? 'Todo bearbeiten' : 'Neues Todo erstellen'}
      size='3xl'
    >
      <Form builder={builder} className='block space-y-6' onSubmit={onSubmit}>
        <RichTextFormField on={builder.fields.content} />
        <GoalSelectField
          on={builder.fields.parentGoalId}
          label='Gehört zu Ziel'
        />
        <TodoSelectField on={builder.fields.parentTodoId} />
        <CheckboxFormField label='Done' on={builder.fields.done} />

        <DialogButtons>
          <Button
            type='button'
            loadingSignal={loadingSignal}
            loadingText='Abbrechen'
            onClick={close}
            variant='secondary'
          >
            Abbrechen
          </Button>
          <Button
            type='submit'
            loadingSignal={loadingSignal}
            loadingText='Speichern'
          >
            Speichern
          </Button>
        </DialogButtons>
      </Form>
    </DialogBase>
  );
};

export const useOpenTodoUpsertModal = () => {
  const open = useOpenModal();

  return useMemo(() => {
    return async (todo?: Partial<Todo>) => {
      await open((_, close) => <TodoUpsertModal todo={todo} close={close} />);
    };
  }, [open]);
};
