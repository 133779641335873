import * as Types from '../../../__generated__/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GoalCreateInput = {
  content: string;
  parentId?: string | null;
  userId: string;
};

export type GoalUpdateInput = {
  content: string;
  id: string;
  parentId?: string | null;
};

export type GetGoalQueryVariables = Types.Exact<{
  id: string;
}>;

export type GetGoalQuery = {
  __typename?: 'Query';
  my: {__typename?: 'User'; goal: {__typename?: 'Goal'; id: string; content: string; parentId?: string | null}};
};

export type CreateGoalMutationVariables = Types.Exact<{
  input: GoalCreateInput;
}>;

export type CreateGoalMutation = {
  __typename?: 'Mutation';
  goalCreate: {__typename?: 'GoalCreatePayload'; success?: boolean | null};
};

export type UpdateGoalMutationVariables = Types.Exact<{
  input: GoalUpdateInput;
}>;

export type UpdateGoalMutation = {
  __typename?: 'Mutation';
  goalUpdate: {
    __typename?: 'GoalUpdatePayload';
    success?: boolean | null;
    errors?: Array<{__typename?: 'GoalTreeMustBeValidTree'; message: string}> | null;
  };
};

export const GetGoalDocument = gql`
  query GetGoal($id: GoalId!) {
    my {
      goal(id: $id) {
        id
        content
        parentId
      }
    }
  }
`;

export function useGetGoalQuery(options: Omit<Urql.UseQueryArgs<GetGoalQueryVariables>, 'query'>) {
  return Urql.useQuery<GetGoalQuery, GetGoalQueryVariables>({query: GetGoalDocument, ...options});
}
export const CreateGoalDocument = gql`
  mutation CreateGoal($input: GoalCreateInput!) {
    goalCreate(create: $input) {
      success
    }
  }
`;

export function useCreateGoalMutation() {
  return Urql.useMutation<CreateGoalMutation, CreateGoalMutationVariables>(CreateGoalDocument);
}
export const UpdateGoalDocument = gql`
  mutation UpdateGoal($input: GoalUpdateInput!) {
    goalUpdate(update: $input) {
      success
      errors {
        ... on Error {
          message
        }
      }
    }
  }
`;

export function useUpdateGoalMutation() {
  return Urql.useMutation<UpdateGoalMutation, UpdateGoalMutationVariables>(UpdateGoalDocument);
}
