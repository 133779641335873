import {
  type FC,
  type PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

type TitleFn = (parentTitle: string) => string;

type TitleNodeConfig = {
  leftDelimiter?: string;
};

type TitleNode = {
  title: string | TitleFn;
  config: TitleNodeConfig;
  children: Set<TitleNode> | null;
};

type NestedTitleContextValue = {
  register: (node: TitleNode) => () => void;
  refreshTitle: () => void;
};

const NestedTitleContext = createContext<NestedTitleContextValue | null>(null);

const recurseTitleNode = (
  node: TitleNode,
  callback: (node: TitleNode) => void,
) => {
  callback(node);

  if (node.children) {
    for (const child of node.children) {
      recurseTitleNode(child, callback);
    }
  }
};

const setTitleFromNode = (node: TitleNode) => {
  const titleFns: TitleFn[] = [];

  recurseTitleNode(node, (n) => {
    const title = n.title;
    return titleFns.push(
      typeof title === 'string'
        ? (parentTitle: string) =>
            parentTitle.length > 0
              ? `${parentTitle}${n.config.leftDelimiter ?? ' | '}${title}`
              : title
        : title,
    );
  });

  const title = titleFns.reduce((t, titleFn) => titleFn(t), '');

  if (title !== document.title) {
    document.title = title;
  }
};

const isolateTitle = (title: TitleFn | string) =>
  typeof title === 'string' ? title : title('');

type TitleProps = {
  title: string | TitleFn;
  config?: TitleNodeConfig;
  asRoot?: boolean;
};

export const Title: FC<PropsWithChildren<TitleProps>> = ({
  title,
  config = {},
  children,
}) => {
  const titleNodeRef = useRef<TitleNode>({title, config, children: null});
  const parentTitleContext = useContext(NestedTitleContext);

  const ownTitleContext = useMemo<NestedTitleContextValue>(
    () => ({
      register: (node: TitleNode) => {
        const ownNode = titleNodeRef.current;

        if (!ownNode.children) ownNode.children = new Set<TitleNode>();

        if (!ownNode.children.has(node)) {
          ownNode.children.add(node);
          if (ownNode.children.size > 1) {
            const titles = Array.from(ownNode.children.values()).map(
              (n) => `"${isolateTitle(n.title)}"`,
            );
            console.warn(
              `<Title /> Element with sibling detected. Parent node with title "${isolateTitle(
                ownNode.title,
              )}" has ${titles.length} direct children: ${titles.join(
                ', ',
              )}. They will be applied in render-order`,
            );
          }
        }

        if (parentTitleContext) {
          parentTitleContext.register(ownNode);
        } else {
          setTitleFromNode(titleNodeRef.current);
        }

        return () => {
          ownNode.children?.delete(node);
          parentTitleContext?.refreshTitle();
        };
      },
      refreshTitle: () => {
        if (parentTitleContext) {
          parentTitleContext.refreshTitle();
        } else {
          setTitleFromNode(titleNodeRef.current);
        }
      },
    }),
    [parentTitleContext],
  );

  useEffect(() => {
    if (parentTitleContext) {
      const unregister = parentTitleContext.register(titleNodeRef.current);

      return () => unregister();
    }
  }, [parentTitleContext]);

  useEffect(() => {
    titleNodeRef.current.title = title;
    titleNodeRef.current.config.leftDelimiter = config.leftDelimiter;
    if (parentTitleContext) {
      parentTitleContext.refreshTitle();
    }
  }, [title, config?.leftDelimiter, parentTitleContext]);

  return (
    <NestedTitleContext.Provider value={ownTitleContext}>
      {children}
    </NestedTitleContext.Provider>
  );
};
