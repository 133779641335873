import {Route, Routes} from 'react-router-dom';
import {PageContainer} from 'components/page-container';
import {Title} from 'components/title';
import {AllItems} from './all-items.tsx';

export const AllItemsRoutes = () => (
  <Routes>
    <Route
      path='*'
      element={
        <PageContainer>
          <Title title='Alle'>
            <AllItems />
          </Title>
        </PageContainer>
      }
    />
  </Routes>
);
