import {
  type FormEvent,
  forwardRef,
  type TextareaHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {twMerge} from 'tailwind-merge';
import mergeRefs from 'utils/mergeRefs';

export type AutosizeTextAreaProps = {
  minRows?: number;
  maxRows?: number;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export const AutosizeTextArea = forwardRef<
  HTMLTextAreaElement,
  AutosizeTextAreaProps
>(({minRows, maxRows, onInput, ...props}, ref) => {
  const textareaRef = useRef<HTMLTextAreaElement>();

  const mergedRef = useMemo(
    () => mergeRefs(textareaRef, ref),
    [textareaRef, ref],
  );

  const proxiedOnInput = (e: FormEvent<HTMLTextAreaElement>) => {
    onInput?.(e);

    const element = textareaRef.current;
    if (!element) return;

    element.style.height = '';
    element.style.height = element.scrollHeight + 'px';
  };

  useEffect(() => {
    const element = textareaRef.current;
    if (!element) return;

    element.style.height = '';
    element.style.height = element.scrollHeight + 'px';
  }, []);

  return (
    <textarea
      rows={minRows}
      {...props}
      className={twMerge('overflow-hidden rounded shadow-sm', props.className)}
      ref={mergedRef}
      onInput={proxiedOnInput}
    />
  );
});

AutosizeTextArea.displayName = 'AutosizeTextArea';
